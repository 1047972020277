import type { Network } from 'data';

import React from 'react';
import styled from 'styled-components';

export interface NetworkLogoProps {
  network: Network;
  onClick: () => void;
}

export const NetworkLogoContainer = styled.img`
  max-height: 100%;
  max-width: 100%;
  filter: grayscale(1) contrast(100) opacity(0.5);

  &:hover {
    filter: opacity(1);
  }
`;

export const NetworkLogo: React.FC<NetworkLogoProps> = ({
  network,
  onClick,
}) => {
  const { logo_url, name } = network;

  return (
    <NetworkLogoContainer
      src={logo_url}
      onClick={onClick}
      alt={name}
    />
  );
}
