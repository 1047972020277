import './App.css';
import * as Page from './page'
import Modal from 'react-modal';
import React from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import Style from 'style';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { ThemeProvider } from 'component-library';
import { TimezoneProvider, ModalProvider } from 'context';

Modal.setAppElement('#portal');

function App() {
  return (
    <TimezoneProvider>
      <ThemeProvider>
        <StyledComponentsThemeProvider theme={Style.Theme.Light}>
          <ModalProvider>
            <BrowserRouter>
              <Routes>
                <Route element={<Page.Admin />}>
                  <Route index element={<Page.Admin.Home />} />
        
                  <Route path="card" element={<Page.Admin.Card />}>
                    <Route index element={<Page.Admin.Card.Manage />} />
                    <Route path="edit/:id" element={<Page.Admin.Card.Edit />} />
                    <Route path="new" element={<Page.Admin.Card.New />} />
                  </Route>
        
                  <Route path="athlete" element={<Page.Admin.Athlete />}>
                    <Route index element={<Page.Admin.Athlete.Manage />} />
                    <Route path="edit/:id" element={<Page.Admin.Athlete.Edit />} />
                    <Route path="new" element={<Page.Admin.Athlete.New />} />
                  </Route>
        
                  <Route path="network" element={<Page.Admin.Network />}>
                    <Route index element={<Page.Admin.Network.Manage />} />
                    <Route path="edit/:id" element={<Page.Admin.Network.Edit />} />
                    <Route path="new" element={<Page.Admin.Network.New />} />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </ModalProvider>
        </StyledComponentsThemeProvider>
      </ThemeProvider>
    </TimezoneProvider>
  );
}

export default App;
