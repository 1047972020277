"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_API_CONFIG = exports.EnvironmentAPIConfig = exports.Environment = void 0;
var Environment;
(function (Environment) {
    Environment["Local"] = "local";
    Environment["Production"] = "production";
})(Environment = exports.Environment || (exports.Environment = {}));
exports.EnvironmentAPIConfig = {
    [Environment.Local]: {
        baseURL: 'http://localhost:3001',
    },
    [Environment.Production]: {
        baseURL: 'https://api.boxing.cards',
    },
};
exports.DEFAULT_API_CONFIG = exports.EnvironmentAPIConfig[Environment.Local];
