"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Definition = exports.BoxShadow = exports.Position = void 0;
const styled_components_1 = require("styled-components");
exports.Position = {
    // Background
    B0: -100,
    B50: -50,
    B100: -0,
    // Midground
    M0: 1,
    M50: 50,
    M100: 100,
    // Foreground
    F0: 101,
    F50: 150,
    F100: 200,
};
exports.BoxShadow = {
    Primary: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    Secondary: 'rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.02) 0px 2px 6px 2px',
};
const Shadow = (0, styled_components_1.css) `
  box-shadow: ${exports.BoxShadow.Primary};
`;
const LightShadow = (0, styled_components_1.css) `
  box-shadow: ${exports.BoxShadow.Secondary};
`;
exports.Definition = {
    Shadow,
    LightShadow,
};
