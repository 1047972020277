"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Menu = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Menu_1 = require("./Menu");
const Flyout_1 = require("./Flyout");
const Menu = (props) => {
    return ((0, jsx_runtime_1.jsx)(Menu_1.Menu, Object.assign({}, props)));
};
exports.Menu = Menu;
exports.Menu.Flyout = Flyout_1.FlyoutMenu;
