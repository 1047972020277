"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cardPopulatedToCardForm = exports.createCardForm = exports.CardFormSchema = void 0;
const R = require("ramda");
const yup = require("yup");
const match_1 = require("../match");
const library_1 = require("library");
exports.CardFormSchema = yup.object({
    location: yup.string(),
    matches: yup.array().of(match_1.MatchFormSchema),
    name: yup.string(),
    networks: yup.array().of(yup.string()),
    start: yup.string(),
});
function createCardForm() {
    return {
        location: '',
        matches: [],
        name: '',
        networks: new Set(),
        start: undefined,
    };
}
exports.createCardForm = createCardForm;
function cardPopulatedToCardForm(card) {
    return {
        location: card.location,
        matches: R.map(match_1.matchToMatchForm, card.matches),
        name: card.name,
        networks: new Set(R.map(R.prop('id'), card.networks)),
        start: (0, library_1.toDateTime)(card.start),
    };
}
exports.cardPopulatedToCardForm = cardPopulatedToCardForm;
