import type { FormComponentProps } from 'component-library';
import type {
  MatchCornersForm as IMatchCornersForm,
  MatchForm as IMatchForm,
} from 'data';

import { DateTime } from 'luxon';
import styled from 'styled-components'
import React from 'react';

import { MatchCategory, WeightClass } from 'data';
import { toMatchName } from 'shared';
import Style from 'style';

import { Expand, Header, Icon, Button, Form, FormRow, DatePicker, FormInput } from 'component-library';
import { MatchCornersForm } from './MatchCornersForm';
import { Round } from 'component/Round';
import { SelectMatchCategory } from './SelectMatchCategory';
import { WeightClass as WeightClassComponent } from 'component/WeightClass';

export interface MatchFormComponentProps extends FormComponentProps<IMatchForm> {
  onRemove: () => void;
}

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${Style.Layout.Spacing.Small};
`;

export const MatchForm: React.FC<MatchFormComponentProps> = ({
  isDisabled,
  onChange,
  onRemove,
  value,
}) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(true);

  return (
    <Form>
      <FormRow>
        <HeaderContent>
          <Header>
            {toMatchName(value)}
          </Header>
          <Controls>
            <Button.Icon
              Icon={Icon.Trash}
              onClick={onRemove}
            />
            <Expand
              isExpanded={isExpanded}
              onClick={(next: boolean) => setIsExpanded(!next)}
            />
          </Controls>
        </HeaderContent>
      </FormRow>
      {isExpanded && (
        <>
          <FormInput<WeightClass, Partial<IMatchForm>>
            Component={WeightClassComponent.Select}
            accessor="weight_class"
            label="Weight Class"
            onChange={onChange}
            source={value}
          />
          <FormRow>
            <FormInput<MatchCategory, Partial<IMatchForm>>
              Component={SelectMatchCategory}
              accessor="category"
              label="Category"
              onChange={onChange}
              source={value}
            />
            <FormInput<number, Partial<IMatchForm>>
              Component={Round.Select}
              accessor="rounds"
              label="Rounds"
              onChange={onChange}
              source={value}
            />
          </FormRow>
          <FormInput<DateTime | undefined, Partial<IMatchForm>>
            Component={DatePicker}
            accessor="start"
            label="Start"
            onChange={onChange}
            source={value}
          />
          <FormInput<IMatchCornersForm, Partial<IMatchForm>>
            Component={MatchCornersForm}
            accessor="corners"
            onChange={onChange}
            source={value}
          />
        </>
      )}
    </Form>
  );
}
