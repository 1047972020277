"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Input_1 = require("./Input");
const Image_1 = require("./Image");
const Text_1 = require("./Text");
const Container_1 = require("./Container");
const Input = (props) => {
    return (0, jsx_runtime_1.jsx)(Input_1.Input, Object.assign({}, props));
};
exports.Input = Input;
exports.Input.Text = Text_1.TextInput;
exports.Input.ContainerStyle = Container_1.InputContainerStyle;
exports.Input.Image = Image_1.ImageInput;
