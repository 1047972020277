"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupByUnique = exports.remove = exports.set = void 0;
const R = require("ramda");
function set(index, value, array) {
    const next = [...array];
    next[index] = value;
    return next;
}
exports.set = set;
function remove(index, array) {
    const next = [...array];
    next.splice(index, 1);
    return next;
}
exports.remove = remove;
function groupByUnique(accessor, items) {
    return R.reduce((acc, item) => {
        return R.set(R.lensProp(accessor(item)), item, acc);
    }, {}, items);
}
exports.groupByUnique = groupByUnique;
