import type { State, ModalManager } from './type';

import React from 'react';
import Modal from 'react-modal';

import { ActionType } from './action';
import { NOOPModal, InitialState } from './state';
import { reducer } from './reducer';

export type { ModalComponentProps, ModalManager } from './type';

export const ModalContext = React.createContext<any>(InitialState);

export interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [ state, dispatch ] = React.useReducer(reducer, InitialState);

  const close = React.useCallback(() => {
    dispatch({ type: ActionType.CloseModal });
  }, [dispatch]);

  const component = React.useMemo(() => {
    return state.modal({ close });
  }, [state, close]);

  return (
    <ModalContext.Provider value={{ state, dispatch, close }}>
      <Modal
        isOpen={state.modal !== NOOPModal}
        onAfterOpen={() => null}
        onRequestClose={() => null}
        style={{}}
        contentLabel="Example Modal"
      >
        {component}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export function useModal(): ModalManager {
  const { state, dispatch, close } = React.useContext(ModalContext);

  const open = React.useCallback((modal: State['modal']) => {
    dispatch({ type: ActionType.OpenModal, payload: modal });
  }, [dispatch]);

  return {
    close,
    modal: state.modal,
    open,
  };
}
