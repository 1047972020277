import type { SelectWeightClassComponent } from './Select';
import type { WeightClassPillComponent } from './Pill';

import { SelectWeightClass } from './Select';
import { WeightClassPill } from './Pill';

export interface WeightClassNamespace {
  Pill: WeightClassPillComponent;
  Select: SelectWeightClassComponent;
}

export const WeightClass: WeightClassNamespace = {
  Pill: WeightClassPill,
  Select: SelectWeightClass,
}
