"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Paragraph = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Accent_1 = require("./Accent");
const Paragraph_1 = require("./Paragraph");
const Paragraph = (props) => {
    return (0, jsx_runtime_1.jsx)(Paragraph_1.Paragraph, Object.assign({}, props));
};
exports.Paragraph = Paragraph;
exports.Paragraph.Accent = Accent_1.AccentParagraph;
