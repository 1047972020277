import type { FormComponentProps } from 'component-library';
import type { Network } from 'data';

import React from 'react';

import API from 'api';
import { Resource } from 'component/resource';

export interface MultiSelectNetworkProps extends FormComponentProps<Set<string>> {
}

export const MultiSelectNetwork: React.FC<MultiSelectNetworkProps> = (props) => {
  const toOption = React.useCallback((network: Network) => {
    return {
      key: network.id,
      label: network.name,
      value: network.id,
    };
  }, []);

  const fetch = React.useCallback(() => {
    return API.Network.find();
  }, []);

  return (
    <Resource.MultiSelect<Network, string>
      {...props}
      fetch={fetch}
      toOption={toOption}
    />
  );
}
