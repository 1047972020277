import type { MatchesFormProps } from './MatchesForm';

import React from 'react';

import { MatchesForm } from './MatchesForm';

export interface MatchNamespace {
  MatchesForm: React.FC<MatchesFormProps>;
}

export const Match: MatchNamespace = {
  MatchesForm,
}
