import type { SelectComponent } from 'component-library';

import { Option, Select } from 'component-library';

export type SelectRoundComponent = SelectComponent<number>;

export const SelectRoundOptions: Option<number>[] = [{
  key: '12',
  label: '12',
  value: 12,
}, {
  key: '11',
  label: '11',
  value: 11,
}, {
  key: '10',
  label: '10',
  value: 10,
}, {
  key: '9',
  label: '9',
  value: 9,
}, {
  key: '8',
  label: '8',
  value: 8,
}, {
  key: '7',
  label: '7',
  value: 7,
}, {
  key: '6',
  label: '6',
  value: 6,
}, {
  key: '5',
  label: '5',
  value: 5,
}, {
  key: '4',
  label: '4',
  value: 4,
}, {
  key: '3',
  label: '3',
  value: 3,
}, {
  key: '2',
  label: '2',
  value: 2,
}, {
  key: '1',
  label: '1',
  value: 1,
}];

export const SelectRound: SelectRoundComponent = (props) => {
  return (
    <Select
      {...props}
      options={SelectRoundOptions}
    />
  );
}
