import Style from 'style';
import styled from 'styled-components';

export const FormContainer = styled.div`
  display: grid;
  row-gap: ${Style.Layout.Spacing.Small};
`;

export const Actions = styled.div`
  align-items: center;
  column-gap: ${Style.Layout.Spacing.Small};
  display: flex;
  justify-content: flex-end;
`;
