import type { Option, SelectComponent } from 'component-library';

import { WeightClass, WeightClassLabelLong } from 'data';

import { Select } from 'component-library';

export type SelectWeightClassComponent = SelectComponent<WeightClass>;

export const SelectWeightClassOptions: Option<WeightClass>[] = [{
  label: WeightClassLabelLong[WeightClass.Heavy],
  value: WeightClass.Heavy,
  key: WeightClass.Heavy,
}, {
  label: WeightClassLabelLong[WeightClass.Cruiser],
  value: WeightClass.Cruiser,
  key: WeightClass.Cruiser,
}, {
  label: WeightClassLabelLong[WeightClass.LightHeavy],
  value: WeightClass.LightHeavy,
  key: WeightClass.LightHeavy,
}, {
  label: WeightClassLabelLong[WeightClass.SuperMiddle],
  value: WeightClass.SuperMiddle,
  key: WeightClass.SuperMiddle,
}, {
  label: WeightClassLabelLong[WeightClass.Middle],
  value: WeightClass.Middle,
  key: WeightClass.Middle,
}, {
  label: WeightClassLabelLong[WeightClass.SuperWelter],
  value: WeightClass.SuperWelter,
  key: WeightClass.SuperWelter,
}, {
  label: WeightClassLabelLong[WeightClass.Welter],
  value: WeightClass.Welter,
  key: WeightClass.Welter,
}, {
  label: WeightClassLabelLong[WeightClass.SuperLight],
  value: WeightClass.SuperLight,
  key: WeightClass.SuperLight,
}, {
  label: WeightClassLabelLong[WeightClass.Light],
  value: WeightClass.Light,
  key: WeightClass.Light,
}, {
  label: WeightClassLabelLong[WeightClass.SuperFeather],
  value: WeightClass.SuperFeather,
  key: WeightClass.SuperFeather,
}, {
  label: WeightClassLabelLong[WeightClass.Feather],
  value: WeightClass.Feather,
  key: WeightClass.Feather,
}, {
  label: WeightClassLabelLong[WeightClass.SuperBantam],
  value: WeightClass.SuperBantam,
  key: WeightClass.SuperBantam,
}, {
  label: WeightClassLabelLong[WeightClass.Bantam],
  value: WeightClass.Bantam,
  key: WeightClass.Bantam,
}, {
  label: WeightClassLabelLong[WeightClass.SuperFly],
  value: WeightClass.SuperFly,
  key: WeightClass.SuperFly,
}, {
  label: WeightClassLabelLong[WeightClass.Fly],
  value: WeightClass.Fly,
  key: WeightClass.Fly,
}, {
  label: WeightClassLabelLong[WeightClass.LightFly],
  value: WeightClass.LightFly,
  key: WeightClass.LightFly,
}, {
  label: WeightClassLabelLong[WeightClass.Minimum],
  value: WeightClass.Minimum,
  key: WeightClass.Minimum,
}];

export const SelectWeightClass: SelectWeightClassComponent = (props) => {
  return (
    <Select.Filter
      {...props}
      options={SelectWeightClassOptions}
    />
  );
}
