"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Size = void 0;
exports.Size = {
    Micro: `0.3rem`,
    Tiny: `0.6rem`,
    Small: `0.8rem`,
    Base: `1.2rem`,
    Increased: `1.4rem`,
    Large: `1.6rem`,
    Giant: `2.0rem`,
};
