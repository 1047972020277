import React from 'react';
import { Outlet } from 'react-router-dom';

import { EditNetwork } from './Edit';
import { ManageNetwork } from './Manage';
import { NewNetwork } from './New';

export interface NetworkNamespace extends React.VoidFunctionComponent {
  Edit: React.VoidFunctionComponent;
  New: React.VoidFunctionComponent;
  Manage: React.VoidFunctionComponent;
}

export const Network: NetworkNamespace = () => {
  return (
    <Outlet/>
  );
}

Network.Edit = EditNetwork;
Network.New = NewNetwork;
Network.Manage = ManageNetwork;
