"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addAuth = void 0;
const axios_1 = require("axios");
const auth0_spa_js_1 = require("@auth0/auth0-spa-js");
/**
 * Retrieve the authentication configuration.
 */
function getAuthConfig({ axios, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield axios.get('/auth/config.json');
        return response.data;
    });
}
/**
 * Get the Auth0 client.
 */
function getAuth0Client({ axios, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const config = yield getAuthConfig({ axios });
        return (0, auth0_spa_js_1.default)({
            audience: config.audience,
            client_id: config.clientId,
            domain: config.domain,
        });
    });
}
/**
 * Add authentication to an existing axios instance.
 */
function addAuth({ authorization, axios, }) {
    const authenticated = axios_1.default.create(axios.defaults);
    authenticated.interceptors.request.use((config) => __awaiter(this, void 0, void 0, function* () {
        config.headers.Authorization = `Bearer ${authorization}`;
        return config;
    }));
    return authenticated;
}
exports.addAuth = addAuth;
