"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScaleSpinner = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_spinners_1 = require("react-spinners");
const constant_1 = require("./constant");
const ScaleSpinner = ({ color = constant_1.DEFAULT_SPINNER_COLOR, speedMultiplier = constant_1.DEFAULT_SPINNER_SPEED_MULTIPLIER, }) => {
    return ((0, jsx_runtime_1.jsx)(react_spinners_1.ScaleLoader, { color: color, speedMultiplier: speedMultiplier }));
};
exports.ScaleSpinner = ScaleSpinner;
