"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatedAtColumn = void 0;
const Cell_1 = require("../Cell");
const constant_1 = require("../constant");
exports.CreatedAtColumn = {
    Cell: Cell_1.Cell.DateTime,
    accessor: 'created_at',
    alignment: constant_1.HorizontalAlignment.Left,
    name: 'Created',
    max: 50,
};
