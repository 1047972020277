import type { FormComponent } from 'component-library';
import type { MatchForm, CardForm as ICardForm } from 'data';

import { DateTime } from 'luxon';

import { Match } from 'component/Match';
import { Network } from 'component/Network';
import { DatePicker, FormRow, Form, FormInput, Input } from 'component-library';

export type CardFormComponent = FormComponent<Partial<ICardForm>>;

export const CardForm: CardFormComponent = ({
  isDisabled,
  onChange,
  value,
}) => {
  return (
    <Form>
      <FormRow>
        <FormInput<string, Partial<ICardForm>>
          onChange={onChange}
          accessor="name"
          label="Name"
          source={value}
          Component={Input.Text}
        />
        <FormInput<string, Partial<ICardForm>>
          onChange={onChange}
          accessor="location"
          label="Location"
          source={value}
          Component={Input.Text}
        />
      </FormRow>
      <FormInput<DateTime | undefined, Partial<ICardForm>>
        Component={DatePicker}
        accessor="start"
        label="Start"
        onChange={onChange}
        source={value}
      />
      <FormInput<Set<string>, Partial<ICardForm>>
        onChange={onChange}
        accessor="networks"
        label="Networks"
        source={value}
        Component={Network.MultiSelect}
      />
      <FormInput<MatchForm[], Partial<ICardForm>, MatchForm[], {
        start: DateTime;
      }>
        onChange={onChange}
        accessor="matches"
        label="Matches"
        source={value}
        Component={Match.MatchesForm}
        props={{ start: value.start }}
      />
    </Form>
  );
}
