"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sentiment = exports.VerticalAlignment = exports.HorizontalAlignment = exports.VerticalDirection = exports.HorizontalDirection = void 0;
var HorizontalDirection;
(function (HorizontalDirection) {
    HorizontalDirection["Left"] = "left";
    HorizontalDirection["Right"] = "right";
})(HorizontalDirection = exports.HorizontalDirection || (exports.HorizontalDirection = {}));
var VerticalDirection;
(function (VerticalDirection) {
    VerticalDirection["Bottom"] = "bottom";
    VerticalDirection["Top"] = "top";
})(VerticalDirection = exports.VerticalDirection || (exports.VerticalDirection = {}));
var HorizontalAlignment;
(function (HorizontalAlignment) {
    HorizontalAlignment["Center"] = "center";
    HorizontalAlignment["Left"] = "left";
    HorizontalAlignment["Right"] = "right";
})(HorizontalAlignment = exports.HorizontalAlignment || (exports.HorizontalAlignment = {}));
var VerticalAlignment;
(function (VerticalAlignment) {
    VerticalAlignment["Bottom"] = "bottom";
    VerticalAlignment["Center"] = "center";
    VerticalAlignment["Top"] = "top";
})(VerticalAlignment = exports.VerticalAlignment || (exports.VerticalAlignment = {}));
var Sentiment;
(function (Sentiment) {
    Sentiment["Action"] = "Action";
    Sentiment["Error"] = "Error";
    Sentiment["Info"] = "Info";
    Sentiment["Neutral"] = "Neutral";
    Sentiment["Success"] = "Success";
})(Sentiment = exports.Sentiment || (exports.Sentiment = {}));
