import type { Athlete } from 'data';
import type { GridComponent, CellRenderer } from 'component-library';

import React from 'react';

import { Cell, Column, Grid, HorizontalAlignment } from 'component-library';
import { athleteToName } from 'shared';

export const NameCell: CellRenderer<Athlete> = ({
  row,
}) => {
  const { original } = row;

  const name: string = React.useMemo(() => {
    return athleteToName(original);
  }, [original]);

  return (
    <Cell.Link
      path={`edit/${original.id}`}
      value={name}
    />
  );
}

const ColumnSchemas = [{
  Cell: NameCell,
  accessor: 'first_name',
  alignment: HorizontalAlignment.Left,
  name: 'Name',
  max: 50,
},
...Column.Timestamps,
];

export const AthleteListTable: GridComponent<Athlete> = ({
  data,
}) => {
  return (
    <Grid<Athlete>
      columnSchemas={ColumnSchemas}
      data={data}
    />
  );
}
