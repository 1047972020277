import type { AthleteForm } from 'data';

import React from 'react';

import { createAthleteForm } from 'data';

import API from 'api';
import { Athlete as AthleteComponent } from 'component/Athlete';
import { Resource } from 'component/resource';
import { BoundedContent } from 'component/Layout/BoundedContent';

export const NewAthlete: React.VoidFunctionComponent = () => {
  const [ initial ] = React.useState<AthleteForm>(createAthleteForm());

  const create = React.useCallback((state: AthleteForm) => {
    return API.Athlete.create(state);
  }, []);

  return (
    <BoundedContent>
      <Resource.Layout
        title="New Athlete"
      >
        <Resource.Create
          initial={initial}
          Form={AthleteComponent.Form}
          create={create}
        />
      </Resource.Layout>
    </BoundedContent>
  );
}
