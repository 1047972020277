"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Card = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Card_1 = require("./Card");
const Content_1 = require("./Content");
const Card = (props) => {
    return (0, jsx_runtime_1.jsx)(Card_1.Card, Object.assign({}, props));
};
exports.Card = Card;
exports.Card.Content = Content_1.CardContent;
