"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Select = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const AsyncFilter_1 = require("./AsyncFilter");
const Filter_1 = require("./Filter");
const Multi_1 = require("./Multi");
const Select_1 = require("./Select");
function Select(props) {
    return (0, jsx_runtime_1.jsx)(Select_1.Select, Object.assign({}, props));
}
exports.Select = Select;
Select.AsyncFilter = AsyncFilter_1.AsyncFilterSelect;
Select.Filter = Filter_1.FilterSelect;
Select.Multi = Multi_1.MultiSelect;
