import type { Network } from 'data';
import type { GridComponent, CellRenderer } from 'component-library';

import { Cell, Column, Grid, HorizontalAlignment } from 'component-library';

export const NameCell: CellRenderer<Network> = ({
  row,
  value,
}) => {
  const { original } = row;

  return (
    <Cell.Link
      path={`edit/${original.id}`}
      value={value}
    />
  );
}

const ColumnSchemas = [{
  Cell: NameCell,
  accessor: 'name',
  alignment: HorizontalAlignment.Left,
  name: 'Name',
  max: 50,
},
...Column.Timestamps,
];

export const NetworkListTable: GridComponent<Network> = ({
  data,
}) => {
  return (
    <Grid<Network>
      columnSchemas={ColumnSchemas}
      data={data}
    />
  );
}
