import React from 'react';
import styled from 'styled-components';

import Style from 'style';
import { Header } from 'component-library';

export interface ResourceLayoutProps {
  children: React.ReactNode;
  title: React.ReactNode;
}

export const ResourceLayoutContainer = styled.div`
  display: grid;
  row-gap: ${Style.Layout.Spacing.Increased};
`;

export const ResourceLayout: React.FC<ResourceLayoutProps> = ({
  children,
  title,
}) => {
  return (
    <ResourceLayoutContainer>
      <Header>{title}</Header>
      {children}
    </ResourceLayoutContainer>
  );
}
