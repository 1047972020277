"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Interval = exports.toArray = exports.reduce = exports.midpoint = exports.split = exports.flatMap = exports.determineDistance = void 0;
const R = require("ramda");
const range_1 = require("../range");
/**
 * Determine the distance of a range.
 */
const determineDistance = (range) => {
    return range.end.value - range.start.value;
};
exports.determineDistance = determineDistance;
/**
 * Flat map over an interval.
 */
const flatMap = (interval, iteratee) => {
    const { distance, start } = interval;
    let result = [];
    let value = start.value;
    let index = 0;
    while (index <= distance) {
        if (interval.isInside(value)) {
            result = result.concat(iteratee(value, index));
        }
        ++index;
        ++value;
    }
    return result;
};
exports.flatMap = flatMap;
/**
 * Split an interval into N parts.
 */
function split(parts, interval) {
    const distance = (0, exports.determineDistance)(interval);
    const size = distance / parts;
    return R.times((part) => {
        const start = interval.start.value + (part * size);
        const end = start + size;
        return new Interval(start, end, range_1.Range.Bound.Closed);
    }, parts);
}
exports.split = split;
/**
 * Determine the midpoint of an interval.
 */
function midpoint(interval) {
    return interval.start.value + ((0, exports.determineDistance)(interval) / 2);
}
exports.midpoint = midpoint;
/**
 * Reduce an interval.
 */
function reduce(interval, iteratee, initial) {
    const { distance, start } = interval;
    let acc = initial;
    let value = start.value;
    let index = 0;
    while (index <= distance) {
        if (interval.isInside(value)) {
            acc = iteratee(acc, value, index);
        }
        ++index;
        ++value;
    }
    return acc;
}
exports.reduce = reduce;
/**
 * Convert an interval into an array.
 */
function toArray(interval) {
    return reduce(interval, (acc, element) => {
        return acc.concat(element);
    }, []);
}
exports.toArray = toArray;
class Interval extends range_1.Range {
    /**
     * Determine the distance of the interval.
     */
    get distance() {
        return (0, exports.determineDistance)(this);
    }
    /**
     * Convert the interval into an array.
     */
    toArray() {
        return toArray(this);
    }
    /**
     * Reduce over the interval.
     */
    reduce(iteratee, initial) {
        return reduce(this, iteratee, initial);
    }
    /**
     * Flat map over the interval.
     */
    flatMap(iteratee) {
        return (0, exports.flatMap)(this, iteratee);
    }
}
exports.Interval = Interval;
