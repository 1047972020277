"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pill = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Pill_1 = require("./Pill");
const Sentiment_1 = require("./Sentiment");
const Pill = (props) => {
    return (0, jsx_runtime_1.jsx)(Pill_1.Pill, Object.assign({}, props));
};
exports.Pill = Pill;
exports.Pill.Sentiment = Sentiment_1.SentimentPill;
