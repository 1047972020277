"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toComparator = void 0;
const constant_1 = require("./constant");
/**
 * Ascending comparator.
 */
function ascend(left, right) {
    return left - right;
}
/**
 * Descending comparator.
 */
function descend(left, right) {
    return right - left;
}
/**
 * Given a sort get a comparator.
 */
function toComparator(sort) {
    switch (sort) {
        case constant_1.SortDirection.Ascend:
            return ascend;
        case constant_1.SortDirection.Descend:
        default:
            return descend;
    }
}
exports.toComparator = toComparator;
