import type { SelectRoundComponent } from './Select';

import { SelectRound } from './Select';

export interface RoundNamespace {
  Select: SelectRoundComponent;
}

export const Round: RoundNamespace = {
  Select: SelectRound,
}
