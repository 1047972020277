"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createResource = exports.remove = exports.create = exports.update = exports.findById = exports.find = exports.toQueryString = exports.parseResponse = void 0;
const R = require("ramda");
/**
 * Parse an API response.
 */
function parseResponse(response) {
    const { data } = response;
    return data;
}
exports.parseResponse = parseResponse;
/**
 * Convert an object into a query string.
 */
function toQueryString(object) {
    if (R.isEmpty(object)) {
        return '';
    }
    return `?${(new URLSearchParams(object)).toString()}`;
}
exports.toQueryString = toQueryString;
/**
 * Find resources.
 */
function find({ axios, parse, path, }) {
    return (query) => __awaiter(this, void 0, void 0, function* () {
        return axios
            .get(path, { params: query })
            .then(parse)
            .then(result => result);
    });
}
exports.find = find;
/**
 * Find a resource.
 */
function findById({ axios, parse, path, }) {
    return (id) => __awaiter(this, void 0, void 0, function* () {
        return axios
            .get(`${path}/${id}`)
            .then(parse)
            .then(result => result);
    });
}
exports.findById = findById;
/**
 * Update a resource.
 */
function update({ axios, parse, path, }) {
    return (id, next) => __awaiter(this, void 0, void 0, function* () {
        return axios
            .put(`${path}/${id}`, next)
            .then(parse)
            .then(result => result);
    });
}
exports.update = update;
/**
 * Create a resource.
 */
function create({ axios, parse, path, }) {
    return (next) => __awaiter(this, void 0, void 0, function* () {
        return axios
            .post(`/${path}`, next)
            .then(parse)
            .then(result => result);
    });
}
exports.create = create;
/**
 * Delete a resource.
 */
function remove({ axios, path, }) {
    return (id) => __awaiter(this, void 0, void 0, function* () {
        return axios.delete(`${path}/${id}`).then(() => undefined);
    });
}
exports.remove = remove;
/**
 * Create a resource API.
 */
function createResource({ axios, path, }) {
    const props = { parse: parseResponse, axios, path };
    return {
        axios,
        create: create(props),
        find: find(props),
        findById: findById(props),
        parse: parseResponse,
        path,
        remove: remove(props),
        update: update(props),
    };
}
exports.createResource = createResource;
