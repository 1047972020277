import type { CardPopulated } from 'data';
import type { GridComponent, CellRenderer } from 'component-library';

import React from 'react';

import { Cell, Column, Grid, HorizontalAlignment } from 'component-library';

export const NameCell: CellRenderer<CardPopulated> = ({
  row,
  value,
}) => {
  const { original } = row;

  return (
    <Cell.Link
      path={`edit/${original.id}`}
      value={value}
    />
  );
}

const ColumnSchemas = [{
  Cell: NameCell,
  accessor: 'name',
  alignment: HorizontalAlignment.Left,
  name: 'Name',
  max: 50,
},
...Column.Timestamps,
];

export const CardListTable: GridComponent<CardPopulated> = ({
  data,
}) => {
  return (
    <Grid<CardPopulated>
      columnSchemas={ColumnSchemas}
      data={data}
    />
  );
}
