"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.networkToNetworkForm = exports.createNetworkForm = exports.NetworkFormSchema = void 0;
const yup = require("yup");
exports.NetworkFormSchema = yup.object({
    name: yup.string(),
    network_url: yup.string(),
    logo_url: yup.string(),
});
function createNetworkForm() {
    return {
        name: '',
        network_url: '',
    };
}
exports.createNetworkForm = createNetworkForm;
function networkToNetworkForm(network) {
    return {
        name: network.name,
        network_url: network.network_url,
        logo_url: network.logo_url,
    };
}
exports.networkToNetworkForm = networkToNetworkForm;
