import type { FormComponent } from 'component-library';
import type { NavigateFunction } from 'react-router'
import type { ReadResourceProps, ReadResourceChildrenProps } from './Read';

import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'

import { ReadResource } from './Read';
import { Card, Button } from 'component-library';
import { FormContainer, Actions } from './style';

export interface EditResourceProps<T> {
  Form: FormComponent<T>;
  empty?: ReadResourceProps<T>['empty'];
  read: ReadResourceProps<T>['read'];
  remove: () => Promise<any>;
  update: (state: T) => Promise<any>;
}

export const EditResourceContainer = styled.div`
`;

export interface EditResourceContentProps<T> extends ReadResourceChildrenProps<T> {
  Form: EditResourceProps<T>['Form'];
  remove: EditResourceProps<T>['remove'];
  update: EditResourceProps<T>['update'];
}

export function EditResourceContent<T>({
  Form,
  remove,
  resource,
  update,
}: EditResourceContentProps<T>) {
  const navigate: NavigateFunction = useNavigate()
  const [ value, setValue ] = React.useState<T>(resource);

  const goToParent = React.useCallback(() => {
    navigate('../');
  }, [navigate]);

  const onSave = React.useCallback(() => {
    update(value)
      .then(() => {
        goToParent();
      });
  }, [update, value, goToParent]);

  const onCancel = React.useCallback(() => {
    goToParent();
  }, [goToParent]);

  const onRemove = React.useCallback(() => {
    remove()
      .then(() => {
        goToParent();
      });
  }, [remove, goToParent]);

  return (
    <Card>
      <Card.Content>
        <FormContainer>
          <Form
            value={value}
            onChange={setValue}
          />
          <Actions>
            <Button.Ghost
              onClick={onCancel}
            >
              Cancel
            </Button.Ghost>
            <Button.Error
              onClick={onRemove}
            >
              Delete
            </Button.Error>
            <Button.Action
              onClick={onSave}
            >
              Save
            </Button.Action>
          </Actions>
        </FormContainer>
      </Card.Content>
    </Card>
  );
}

/**
 * Component used to edit an existing resource.
 */
export function EditResource<T>({
  Form,
  empty,
  read,
  remove,
  update,
}: EditResourceProps<T>) {
  return (
    <EditResourceContainer>
      <ReadResource<T>
        read={read}
        empty={empty}
      >
        {(readResourceChildrenProps) => (
          <EditResourceContent
            Form={Form}
            remove={remove}
            update={update}
            {...readResourceChildrenProps}
          />
        )}
      </ReadResource>
    </EditResourceContainer>
  );
};
