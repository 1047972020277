import type { FormComponent } from 'component-library';
import type { Athlete } from 'data';

import { Form, FormInput, Input } from 'component-library';

export const AthleteForm: FormComponent<Partial<Athlete>> = ({
  isDisabled,
  onChange,
  value,
}) => {
  return (
    <Form>
      <FormInput<string, Partial<Athlete>>
        onChange={onChange}
        accessor="first_name"
        label="First Name"
        source={value}
        Component={Input.Text}
      />
      <FormInput<string, Partial<Athlete>>
        onChange={onChange}
        accessor="last_name"
        label="Last Name"
        source={value}
        Component={Input.Text}
      />
      <FormInput<string, Partial<Athlete>>
        onChange={onChange}
        accessor="prefix"
        label="Prefix"
        source={value}
        Component={Input.Text}
      />
      <FormInput<string, Partial<Athlete>>
        onChange={onChange}
        accessor="suffix"
        label="Suffix"
        source={value}
        Component={Input.Text}
      />
    </Form>
  );
}
