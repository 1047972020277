import type { Athlete } from 'data';
import type { FilterSelectInputProps, FormComponentProps } from 'component-library';
import type { ModalManager } from 'context';

import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import API from 'api';
import Style from 'style';
import { Icon, Button, Input, Select } from 'component-library';
import { NewAthleteModal } from 'component/Athlete/NewAthleteModal';
import { athleteToName } from 'shared';
import { useModal } from 'context';

export interface SelectAthleteProps extends FormComponentProps<string, Athlete> {
}

export const IconContainer = styled.div`
  position: absolute;
  right: ${Style.Layout.Spacing.Small};
  height: 100%;
  display: flex;
  align-items: center;
`;

export const FilterSelectInput: React.FC<FilterSelectInputProps> = ({
  filter,
  open,
  toggle,
}) => {
  const modal: ModalManager = useModal();

  return (
    <Input.Text
      onClick={() =>  {
        toggle();

        filter.clearSearch();
      }}
      onChange={(next: string) => {
        open();

        filter.onInputChange(next);
      }}
      value={filter.input}
    >
      <IconContainer>
        <Button.Icon
          Icon={Icon.UserPlus}
          onClick={() => {
            modal.open((props) => <NewAthleteModal {...props}/>);
          }}
        />
      </IconContainer>
    </Input.Text>
  );
}

export const SelectAthlete: React.FC<SelectAthleteProps> = (props) => {
  const toOption = React.useCallback((athlete: Athlete) => {
    return {
      key: athlete.id,
      label: athleteToName(athlete),
      value: athlete.id,
      context: athlete,
    };
  }, []);

  const onSearch = React.useCallback((query: string) => {
    return API.Athlete.find({ name: query })
      .then((results: Athlete[]) => {
        return R.map(toOption, results);
      });
  }, [toOption]);

  return (
    <Select.AsyncFilter<string, Athlete>
      {...props}
      onSearch={onSearch}
      Input={FilterSelectInput}
    />
  );
}
