"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.athleteToAthleteForm = exports.createAthleteForm = exports.AthleteFormSchema = void 0;
const yup = require("yup");
exports.AthleteFormSchema = yup.object({
    first_name: yup.string(),
    last_name: yup.string(),
    suffix: yup.string().optional(),
    prefix: yup.string().optional(),
});
function createAthleteForm() {
    return {
        first_name: '',
        last_name: '',
        suffix: undefined,
        prefix: undefined,
    };
}
exports.createAthleteForm = createAthleteForm;
function athleteToAthleteForm(athlete) {
    return {
        first_name: athlete.first_name,
        last_name: athlete.last_name,
        suffix: athlete.suffix,
        prefix: athlete.prefix,
    };
}
exports.athleteToAthleteForm = athleteToAthleteForm;
