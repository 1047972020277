import type { SelectComponent } from 'component-library';

import { MatchCategory, MatchCategoryLabel } from 'data';

import { Option, Select } from 'component-library';

export type SelectMatchCategoryComponent = SelectComponent<MatchCategory>;

export const SelectMatchCategoryOptions: Option<MatchCategory>[] = [{
  label: MatchCategoryLabel[MatchCategory.Main],
  value: MatchCategory.Main,
  key: MatchCategory.Main,
}, {
  label: MatchCategoryLabel[MatchCategory.Middle],
  value: MatchCategory.Middle,
  key: MatchCategory.Middle,
}, {
  label: MatchCategoryLabel[MatchCategory.Lower],
  value: MatchCategory.Lower,
  key: MatchCategory.Lower,
}];

export const SelectMatchCategory: SelectMatchCategoryComponent = (props) => {
  return (
    <Select
      {...props}
      options={SelectMatchCategoryOptions}
    />
  );
}
