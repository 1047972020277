import styled from 'styled-components';

import Style from 'style';

export interface BoundedContentProps {
  children: React.ReactNode;
}

export const BoundedContentContainer = styled.div`
  max-width: ${Style.Layout.Breakpoint.Max};
`

export const BoundedContent: React.FC<BoundedContentProps> = ({ children }) => {
  return (
    <BoundedContentContainer>
      {children}
    </BoundedContentContainer>
  );
}
