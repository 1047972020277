import type { Option } from 'component-library';
import type { RawTimeZone } from '@vvo/tzdb';
import type { TimezoneManager } from './type';

import * as R from 'ramda';
import React from 'react';
import { Zone, IANAZone } from 'luxon';
import { rawTimeZones } from '@vvo/tzdb';

import { groupByUnique } from 'library';

import IANNANames from './ianna';
import { ActionType } from './action';
import { InitialState } from './state';
import { reducer } from './reducer';

export type { TimeBasedComponent, TimezoneManager } from './type';

export const TimezoneContext = React.createContext<any>(InitialState);

export interface TimezoneProviderProps {
  children: React.ReactNode;
}

export const TimezoneProvider: React.FC<TimezoneProviderProps> = ({ children }) => {
  const [ state, dispatch ] = React.useReducer(reducer, InitialState);

  return (
    <TimezoneContext.Provider value={{ state, dispatch }}>
      {children}
    </TimezoneContext.Provider>
  );
};

export function normalizeTimezone({
  name,
  zone,
}: {
  name: string,
  zone: RawTimeZone | undefined,
}): string {
  const label: string = name.replaceAll('_', ' ');
  const postfix: string = R.isNil(zone) ? '' : ` (${zone.abbreviation})`;

  return `${label}${postfix}`;
}

export function useTimezone(): TimezoneManager {
  const { state, dispatch } = React.useContext(TimezoneContext);

  const supportedNames: string[] = IANNANames;

  const timeZonesByName: { [key: string]: RawTimeZone } = React.useMemo(() => {
    return groupByUnique<RawTimeZone>(R.prop('name'), rawTimeZones);
  }, []);

  const options: Option<Zone>[] = React.useMemo(() => {
    return R.map((name: string): Option<Zone> => {
      const zone: RawTimeZone | undefined = R.prop(name, timeZonesByName);

      return {
        key: name,
        label: normalizeTimezone({ name, zone }),
        value: IANAZone.create(name),
      };
    }, supportedNames);
  }, [supportedNames, timeZonesByName]);

  const sortedOptions: Option<Zone>[] = React.useMemo(() => {
    return R.sortBy(R.prop('label'), options);
  }, [options]);

  const setTimezone = React.useCallback((zone: Zone) => {
    dispatch({ type: ActionType.SetTimezone, payload: zone });
  }, [dispatch]);

  return {
    options: sortedOptions,
    setTimezone,
    supportedNames,
    timezone: state.timezone,
  };
}
