"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dark = exports.Layout = exports.Text = void 0;
const light_1 = require("./light");
exports.Text = {
    Primary: '#EBEBEB',
    Secondary: '#A4A5A5',
    Tertiary: '#454444',
};
exports.Layout = {
    Foreground: '#1C1D1F',
    Darkest: '#000000',
    Background: '#131415',
    Lightest: '#FFFFFF',
    Line: '#2F2F2F',
};
exports.Dark = {
    Color: {
        Accent: light_1.Light.Color.Accent,
        Layout: exports.Layout,
        Text: exports.Text,
        Sentiment: Object.assign(Object.assign({}, light_1.Light.Color.Sentiment), { Neutral: {
                Primary: exports.Layout.Background,
                Seconary: exports.Layout.Foreground,
                Text: exports.Text.Secondary,
            } }),
    },
    Text: light_1.Light.Text,
};
