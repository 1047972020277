"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = void 0;
const action_1 = require("./action");
function reducer(state, action) {
    switch (action.type) {
        case action_1.ActionType.SetTimezone:
            return {
                timezone: action.payload
            };
        default:
            return state;
    }
}
exports.reducer = reducer;
;
