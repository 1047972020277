"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Definition = exports.Weight = exports.Size = void 0;
const styled_components_1 = require("styled-components");
const GrowthFactor = '1.00vw';
exports.Size = {
    Micro: `0.3rem`,
    Tiny: `0.6rem`,
    Small: `0.8rem`,
    Base: `0.9rem`,
    Increased: `1.4rem`,
    Large: `1.6rem`,
    Giant: `2.0rem`,
};
exports.Weight = {
    Light: '100',
    Base: '300',
    Heavy: '500',
    Thick: '900',
};
const Truncate = (0, styled_components_1.css) `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
function getPrimaryFontFamiliy({ theme }) {
    return `${theme.Text.Family.Primary}, ${theme.Text.Family.Fallback}`;
}
function getSecondaryFontFamily({ theme }) {
    return `${theme.Text.Family.Secondary}, ${theme.Text.Family.Fallback}`;
}
const Base = (0, styled_components_1.css) `
  font-family: ${getPrimaryFontFamiliy};
  font-size: ${exports.Size.Base};
  line-height: 1em;
`;
const Header = (0, styled_components_1.css) `
  ${Base};
  font-size: ${exports.Size.Increased};
  font-weight: ${exports.Weight.Heavy};
`;
const Subheader = (0, styled_components_1.css) `
  ${Base};
  font-size: ${exports.Size.Increased};
  font-weight: ${exports.Weight.Heavy};
`;
const Subtitle = (0, styled_components_1.css) `
  ${Base};
  font-size: ${exports.Size.Tiny};
  font-weight: ${exports.Weight.Base};
`;
const Paragraph = (0, styled_components_1.css) `
  ${Base};
  font-weight: ${exports.Weight.Base};
`;
const Label = (0, styled_components_1.css) `
  ${Paragraph};
  font-weight: ${exports.Weight.Heavy};
`;
const Footer = (0, styled_components_1.css) `
  ${Base};
  font-size: ${exports.Size.Small};
  font-weight: ${exports.Weight.Base};
`;
const HeaderLabel = (0, styled_components_1.css) `
  ${Header};
  font-size: ${exports.Size.Small};
  text-transform: uppercase;
`;
const AccentBase = (0, styled_components_1.css) `
  font-family: ${getSecondaryFontFamily};
  font-size: ${exports.Size.Base};
  line-height: 1em;
  margin-top: -0.15em;
`;
const AccentHeader = (0, styled_components_1.css) `
  ${AccentBase};
  font-size: ${exports.Size.Small};
  font-weight: ${exports.Weight.Heavy};
`;
const AccentParagraph = (0, styled_components_1.css) `
  ${AccentBase};
  font-size: ${exports.Size.Tiny};
  font-weight: ${exports.Weight.Light};
  letter-spacing: -0.03em;
`;
const FitText = (0, styled_components_1.css) `
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;
exports.Definition = {
    AccentBase,
    AccentHeader,
    AccentParagraph,
    Base,
    FitText,
    Footer,
    Header,
    HeaderLabel,
    Label,
    Paragraph,
    Subheader,
    Subtitle,
    Truncate,
};
