"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findUndercardIndex = exports.findFirstMatch = exports.sortMatches = exports.isUndercard = exports.matchToPriority = exports.matchCategoryToPriority = exports.groupByMatchCategory = exports.matchCategoryToLabel = exports.cornerToLabel = exports.weightClassToLabelLong = exports.weightClassToLabelShort = exports.toMatchName = void 0;
const R = require("ramda");
const library_1 = require("library");
const data_1 = require("data");
const athlete_1 = require("../athlete");
/**
 * Given a match get the match name.
 */
function toMatchName(match) {
    const redCornerAthlete = R.path([data_1.Corner.Red, 'athlete'], match);
    const blueCornerAthlete = R.path([data_1.Corner.Blue, 'athlete'], match);
    if (redCornerAthlete && blueCornerAthlete) {
        const redCorner = (0, athlete_1.athleteToName)(R.path([data_1.Corner.Red, 'athlete'], match));
        const blueCorner = (0, athlete_1.athleteToName)(R.path([data_1.Corner.Blue, 'athlete'], match));
        return `${redCorner} vs. ${blueCorner}`;
    }
    return 'Untitled';
}
exports.toMatchName = toMatchName;
/**
 * Given a weight class get its short label.
 */
function weightClassToLabelShort(weightClass) {
    return R.propOr('Unknown', weightClass, data_1.WeightClassLabelShort);
}
exports.weightClassToLabelShort = weightClassToLabelShort;
/**
 * Given a weight class get its long label.
 */
function weightClassToLabelLong(weightClass) {
    return R.propOr('Unknown', weightClass, data_1.WeightClassLabelLong);
}
exports.weightClassToLabelLong = weightClassToLabelLong;
/**
 * Given a corner get its label.
 */
function cornerToLabel(corner) {
    return R.propOr('Unknown', corner, data_1.CornerLabel);
}
exports.cornerToLabel = cornerToLabel;
/**
 * Given a match category get its label.
 */
function matchCategoryToLabel(category) {
    return R.propOr('Unknown', category, data_1.MatchCategoryLabel);
}
exports.matchCategoryToLabel = matchCategoryToLabel;
/**
 * Given matches group them by their match category.
 */
function groupByMatchCategory(matches) {
    return R.groupBy(R.prop('category'), matches);
}
exports.groupByMatchCategory = groupByMatchCategory;
/**
 * Given a match category get its priority.
 *
 * Lower numbers have higher priority.
 */
function matchCategoryToPriority(category) {
    return R.indexOf(category, data_1.MatchCategoryPriority);
}
exports.matchCategoryToPriority = matchCategoryToPriority;
/**
 * Given a match get its priority (see matchCategoryToPriority).
 */
function matchToPriority(match) {
    return R.pipe(R.prop('category'), R.flip(R.indexOf)(data_1.MatchCategoryPriority))(match);
}
exports.matchToPriority = matchToPriority;
/**
 * Determine if a match category is considered an undercard match.
 */
function isUndercard(category) {
    const priority = matchCategoryToPriority(category);
    return priority > 0;
}
exports.isUndercard = isUndercard;
/**
 * Sort matches.
 */
function sortMatches(matches) {
    return R.sortWith([
        R.descend(R.pipe(R.prop('start'), library_1.toDateTime, (next) => next.toUnixInteger()))
    ], matches);
}
exports.sortMatches = sortMatches;
/**
 * Find the first match to take place.
 */
function findFirstMatch(matches) {
    return R.pipe(sortMatches, R.last)(matches);
}
exports.findFirstMatch = findFirstMatch;
/**
 * Given SORTED matches get the undercard start index.
 */
function findUndercardIndex(matches) {
    return R.findIndex((match) => {
        return isUndercard(match.category);
    }, matches);
}
exports.findUndercardIndex = findUndercardIndex;
