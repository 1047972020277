import type { FormComponent } from 'component-library';
import type { Athlete, MatchCornerForm as IMatchCornerForm } from 'data';

import { Form, FormInput } from 'component-library';
import { Athlete as AthleteComponent } from 'component/Athlete';

export type MatchCornerFormComponent = FormComponent<IMatchCornerForm, any>;

export const MatchCornerForm: MatchCornerFormComponent = ({
  onChange,
  value,
}) => {
  return (
    <Form>
      <FormInput<string, IMatchCornerForm, string, {}, Athlete>
        Component={AthleteComponent.Select}
        accessor="athlete_id"
        onChange={(...props) => {
          onChange(...props)
        }}
        source={value}
      />
    </Form>
  );
}
