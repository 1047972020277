import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Button } from 'component-library';

export const UniversalLogin: React.VoidFunctionComponent = () => {
  const { loginWithRedirect } = useAuth0();

  const onClick = React.useCallback(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return (
    <Button
      onClick={onClick}
    >
      Log In
    </Button>
  );
}
