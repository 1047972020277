"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Sentiment_1 = require("./Sentiment");
const constant_1 = require("../constant");
const ErrorButton = (props) => {
    return ((0, jsx_runtime_1.jsx)(Sentiment_1.SentimentButton, Object.assign({}, props, { sentiment: constant_1.Sentiment.Error })));
};
exports.ErrorButton = ErrorButton;
