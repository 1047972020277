"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toTotal = exports.sumScores = exports.addScores = exports.addCornerScores = exports.scoresToOutcomes = exports.scoresAreComplete = exports.scoreIsComplete = exports.cornerScoreIsComplete = exports.scoreToOutcomes = exports.pointsToOutcome = void 0;
const R = require("ramda");
const data_1 = require("data");
/**
 * Given points determine the outcome.
 */
function pointsToOutcome(left, right) {
    if (R.isNil(left) || R.isNil(right)) {
        return data_1.Outcome.Undecided;
    }
    else if (left > right) {
        return data_1.Outcome.Win;
    }
    else if (left < right) {
        return data_1.Outcome.Lose;
    }
    return data_1.Outcome.Draw;
}
exports.pointsToOutcome = pointsToOutcome;
/**
 * Given a score determine the outcomes for each corner.
 */
function scoreToOutcomes(score) {
    const blue = toTotal(R.prop(data_1.Corner.Blue, score));
    const red = toTotal(R.prop(data_1.Corner.Red, score));
    return {
        [data_1.Corner.Blue]: pointsToOutcome(blue, red),
        [data_1.Corner.Red]: pointsToOutcome(red, blue),
    };
}
exports.scoreToOutcomes = scoreToOutcomes;
/**
 * Determine if a corner score is complete.
 */
function cornerScoreIsComplete(cornerScore) {
    return !R.isNil(cornerScore.points);
}
exports.cornerScoreIsComplete = cornerScoreIsComplete;
/**
 * Determine if a score is complete.
 */
function scoreIsComplete(score) {
    return (cornerScoreIsComplete(R.prop(data_1.Corner.Blue, score)) &&
        cornerScoreIsComplete(R.prop(data_1.Corner.Red, score)));
}
exports.scoreIsComplete = scoreIsComplete;
/**
 * Determine if all scores are complete.
 */
function scoresAreComplete(scores) {
    return R.all(scoreIsComplete, scores);
}
exports.scoresAreComplete = scoresAreComplete;
/**
 * Given scores determine the outcomes for each corner.
 */
function scoresToOutcomes({ match, scores, }) {
    if (R.length(scores) !== match.rounds ||
        !scoresAreComplete(scores)) {
        return {
            [data_1.Corner.Blue]: data_1.Outcome.Undecided,
            [data_1.Corner.Red]: data_1.Outcome.Undecided,
        };
    }
    const score = sumScores(scores);
    return scoreToOutcomes(score);
}
exports.scoresToOutcomes = scoresToOutcomes;
/**
 * Add two corner scores.
 */
function addCornerScores(left, right) {
    return {
        deductions: (left.deductions || 0) + (right.deductions || 0),
        points: (left.points || 0) + (right.points || 0),
    };
}
exports.addCornerScores = addCornerScores;
/**
 * Add two scores.
 */
function addScores(left, right) {
    const getCornerScore = R.propOr(data_1.DEFAULT_CORNER_SCORE);
    return {
        [data_1.Corner.Blue]: addCornerScores(getCornerScore(data_1.Corner.Blue, left), getCornerScore(data_1.Corner.Blue, right)),
        [data_1.Corner.Red]: addCornerScores(getCornerScore(data_1.Corner.Red, left), getCornerScore(data_1.Corner.Red, right)),
    };
}
exports.addScores = addScores;
/**
 * Sum a list of scores.
 */
function sumScores(scores) {
    return R.reduce(addScores, data_1.DEFAULT_SCORE, scores);
}
exports.sumScores = sumScores;
/**
 * Calculate the total for a corner score.
 */
function toTotal(score) {
    if (R.isNil(score.points)) {
        return undefined;
    }
    return (score.points || 0) - (score.deductions || 0);
}
exports.toTotal = toTotal;
