import type { Network as INetwork } from 'data';

import React from 'react';
import styled from 'styled-components';

import { NetworkLogo } from './Logo';

export interface NetworkProps {
  network: INetwork;
  onClick: () => void;
}

export const NetworkContainer = styled.div`
  cursor: pointer;
`

export const Network: React.FC<NetworkProps> = ({
  network,
  onClick,
}) => {
  return (
    <NetworkContainer>
      <NetworkLogo
        network={network}
        onClick={onClick}
      />
    </NetworkContainer>
  );
}
