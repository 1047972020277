"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.List = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const List_1 = require("./List");
const Item_1 = require("./Item");
const List = (props) => {
    return (0, jsx_runtime_1.jsx)(List_1.List, Object.assign({}, props));
};
exports.List = List;
exports.List.Item = Item_1.ListItem;
