import type { CardForm } from 'data';

import React from 'react';

import { cardPopulatedToCardForm } from 'data';

import API from 'api';
import { Card as CardComponent } from 'component/Card';
import { Resource } from 'component/resource';
import { useParams } from 'hook';
import { BoundedContent } from 'component/Layout/BoundedContent';

export const EditCard: React.VoidFunctionComponent = () => {
  const { id } = useParams<{ id: string }>();

  const read = React.useCallback(() => {
    return API.Card.findById(id).then(cardPopulatedToCardForm);
  }, [id]);

  const update = React.useCallback((state: CardForm) => {
    return API.Card.update(id, {
      ...state,
      networks: Array.from(state.networks),
    });
  }, [id]);

  const remove = React.useCallback(() => {
    return API.Card.remove(id);
  }, [id]);

  return (
    <BoundedContent>
      <Resource.Layout
        title="Edit Card"
      >
        <Resource.Edit<CardForm>
          Form={CardComponent.Form}
          read={read}
          remove={remove}
          update={update}
        />
      </Resource.Layout>
    </BoundedContent>
  );
}
