import type { FormComponentProps } from 'component-library';
import type { MatchForm as IMatchForm } from 'data';

import { createMatchForm } from 'data';

import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';

import { remove } from 'library';

import Style from 'style';
import { Button } from 'component-library';
import { MatchForm } from './Form';

export const MatchesFormContainer = styled.div`
  display: grid;
  row-gap: ${Style.Layout.Spacing.Base};
`;

export const MatchFormContainer = styled.div`
  ${Style.Design.Definition.Bounding};
  padding: ${Style.Layout.Spacing.Increased};
`;

export interface MatchesFormProps extends FormComponentProps<IMatchForm[]> {
  start: DateTime;
}

export const MatchesForm: React.FC<MatchesFormProps> = ({
  isDisabled,
  onChange,
  value,
  start,
}) => {
  // Add a match.
  const addMatch = React.useCallback(() => {
    const next: IMatchForm[] = value.concat(createMatchForm({
      start,
    }));

    onChange(next);
  }, [value, onChange, start]);

  // Change a match.
  const changeMatch = React.useCallback((index: number, match: IMatchForm) => {
    const next: IMatchForm[] = R.set(R.lensIndex(index), match, value);

    onChange(next);
  }, [value, onChange]);

  // Remove a match.
  const removeMatch = React.useCallback((index: number) => {
    const next: IMatchForm[] = remove(index, value);

    onChange(next);
  }, [value, onChange]);

  return (
    <MatchesFormContainer>
      {R.addIndex<IMatchForm>(R.map)((matchForm: IMatchForm, index: number) => {
        return (
          <MatchFormContainer key={index}>
            <MatchForm
              key={index}
              value={matchForm}
              onRemove={() => {
                removeMatch(index);
              }}
              onChange={(next: IMatchForm) => {
                changeMatch(index, next);
              }}
            />
          </MatchFormContainer>
        );
      }, value)}
      <Button
        onClick={addMatch}
      >
        Add Match
      </Button>
    </MatchesFormContainer>
  );
}
