"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Breakpoint = exports.Spacing = void 0;
const GrowthFactor = '0.5vw';
exports.Spacing = {
    Micro: `0.3rem`,
    Tiny: `0.6rem`,
    Small: `0.8rem`,
    Base: `1.2rem`,
    Increased: `1.4rem`,
    Large: `1.6rem`,
    Giant: `2.0rem`,
};
exports.Breakpoint = {
    Max: '720px',
};
