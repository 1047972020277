export enum ActionType {
  OpenModal = 'open_modal',
  CloseModal = 'close_modal',
};

export interface OpenModalAction {
  payload: any;
  type: ActionType.OpenModal;
}

export interface CloseModalAction {
  type: ActionType.CloseModal;
}

export type Action =
  OpenModalAction |
  CloseModalAction;
