import type { NetworkForm } from 'data';

import React from 'react';

import { networkToNetworkForm } from 'data';

import API from 'api';
import { Network as NetworkComponent } from 'component/Network';
import { Resource } from 'component/resource';
import { useParams } from 'hook';
import { BoundedContent } from 'component/Layout/BoundedContent';

export const EditNetwork: React.VoidFunctionComponent = () => {
  const { id } = useParams<{ id: string }>();

  const read = React.useCallback(() => {
    return API.Network.findById(id).then(networkToNetworkForm);
  }, [id]);

  const update = React.useCallback((state: NetworkForm) => {
    return API.Network.update(id, state);
  }, [id]);

  const remove = React.useCallback(() => {
    return API.Network.remove(id);
  }, [id]);

  return (
    <BoundedContent>
      <Resource.Layout
        title="Edit Network"
      >
        <Resource.Edit
          Form={NetworkComponent.Form}
          read={read}
          remove={remove}
          update={update}
        />
      </Resource.Layout>
    </BoundedContent>
  );
}
