"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.athleteToName = void 0;
/**
 * Given an athlete get their name.
 */
function athleteToName(athlete) {
    const { first_name, last_name } = athlete;
    const suffix = athlete.suffix ? ` ${athlete.suffix}` : '';
    const prefix = athlete.prefix ? `${athlete.prefix} ` : '';
    return `${prefix}${first_name} ${last_name}${suffix}`;
}
exports.athleteToName = athleteToName;
