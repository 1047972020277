"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WeightClassLabelShort = exports.WeightClassLabelLong = exports.WeightClass = exports.MatchCategoryLabel = exports.MatchCategoryPriority = exports.MatchCategory = exports.CornerLabel = exports.Corner = void 0;
var Corner;
(function (Corner) {
    Corner["Blue"] = "blue";
    Corner["Red"] = "red";
})(Corner = exports.Corner || (exports.Corner = {}));
exports.CornerLabel = {
    [Corner.Blue]: 'Blue',
    [Corner.Red]: 'Red',
};
var MatchCategory;
(function (MatchCategory) {
    MatchCategory["Lower"] = "lower";
    MatchCategory["Main"] = "main";
    MatchCategory["Middle"] = "middle";
})(MatchCategory = exports.MatchCategory || (exports.MatchCategory = {}));
exports.MatchCategoryPriority = [
    MatchCategory.Main,
    MatchCategory.Middle,
    MatchCategory.Lower,
];
exports.MatchCategoryLabel = {
    [MatchCategory.Lower]: 'Undercard (Low)',
    [MatchCategory.Main]: 'Main Event',
    [MatchCategory.Middle]: 'Undercard (Middle)',
};
var WeightClass;
(function (WeightClass) {
    WeightClass["Minimum"] = "minimum";
    WeightClass["LightFly"] = "light-fly";
    WeightClass["Fly"] = "fly";
    WeightClass["SuperFly"] = "super-fly";
    WeightClass["Bantam"] = "bantam";
    WeightClass["SuperBantam"] = "super-bantam";
    WeightClass["Feather"] = "feather";
    WeightClass["SuperFeather"] = "super-feather";
    WeightClass["Light"] = "light";
    WeightClass["SuperLight"] = "super-light";
    WeightClass["Welter"] = "welter";
    WeightClass["SuperWelter"] = "super-welter";
    WeightClass["Middle"] = "middle";
    WeightClass["SuperMiddle"] = "super-middle";
    WeightClass["LightHeavy"] = "light-heavy";
    WeightClass["Cruiser"] = "cruiser";
    WeightClass["Heavy"] = "heavy";
})(WeightClass = exports.WeightClass || (exports.WeightClass = {}));
exports.WeightClassLabelLong = {
    [WeightClass.Minimum]: 'Minimumweight',
    [WeightClass.LightFly]: 'Light Flyweight',
    [WeightClass.Fly]: 'Flyweight',
    [WeightClass.SuperFly]: 'Super Flyweight',
    [WeightClass.Bantam]: 'Bantamweight',
    [WeightClass.SuperBantam]: 'Super Bantamweight',
    [WeightClass.Feather]: 'Featherweight',
    [WeightClass.SuperFeather]: 'Super Featherweight',
    [WeightClass.Light]: 'Lightweight',
    [WeightClass.SuperLight]: 'Super Lightweight',
    [WeightClass.Welter]: 'Welterweight',
    [WeightClass.SuperWelter]: 'Super Welterweight',
    [WeightClass.Middle]: 'Middleweight',
    [WeightClass.SuperMiddle]: 'Super Middleweight',
    [WeightClass.LightHeavy]: 'Light Heavyweight',
    [WeightClass.Cruiser]: 'Cruiserweight',
    [WeightClass.Heavy]: 'Heavyweight',
};
exports.WeightClassLabelShort = {
    [WeightClass.Minimum]: 'Minimum',
    [WeightClass.LightFly]: 'Light Fly',
    [WeightClass.Fly]: 'Fly',
    [WeightClass.SuperFly]: 'Super Fly',
    [WeightClass.Bantam]: 'Bantam',
    [WeightClass.SuperBantam]: 'Super Bantam',
    [WeightClass.Feather]: 'Feather',
    [WeightClass.SuperFeather]: 'Super Feather',
    [WeightClass.Light]: 'Light',
    [WeightClass.SuperLight]: 'Super Light',
    [WeightClass.Welter]: 'Welter',
    [WeightClass.SuperWelter]: 'Super Welter',
    [WeightClass.Middle]: 'Middle',
    [WeightClass.SuperMiddle]: 'Super Middle',
    [WeightClass.LightHeavy]: 'Light Heavy',
    [WeightClass.Cruiser]: 'Cruiser',
    [WeightClass.Heavy]: 'Heavy',
};
