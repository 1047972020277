import type { CreateResourceProps } from './Create';
import type { EditResourceProps } from './Edit';
import type { ManageResourceProps } from './Manage';
import type { MultiResourceSelectProps } from './MultiSelect';
import type { ReadResourceProps } from './Read';
import type { ResourceLayoutProps } from './Layout';
import type { SelectResourceProps } from './Select';

import React from 'react'

import { CreateResource } from './Create';
import { EditResource } from './Edit';
import { ManageResource } from './Manage';
import { MultiResourceSelect } from './MultiSelect';
import { ReadResource } from './Read';
import { ResourceLayout } from './Layout';
import { SelectResource } from './Select';

export interface ResourceNamespace extends React.FC<ReadResourceProps> {
  Edit: <T = any>(props: EditResourceProps<T>) => JSX.Element | null;
  Create: <T = any>(props: CreateResourceProps<T>) => JSX.Element | null;
  Manage: <T extends object>(props: ManageResourceProps<T>) => JSX.Element | null;
  Read: <T = any>(props: ReadResourceProps<T>) => JSX.Element | null;
  Select: <T, V = T>(props: SelectResourceProps<T, V>) => JSX.Element | null;
  MultiSelect: <T, V = T>(props: MultiResourceSelectProps<T, V>) => JSX.Element | null;
  Layout: (props: ResourceLayoutProps) => JSX.Element | null;
}

export const Resource: ResourceNamespace = (props: ReadResourceProps) => {
  return <ReadResource {...props} />
}

Resource.Create = CreateResource;
Resource.Edit = EditResource;
Resource.Layout = ResourceLayout;
Resource.Manage = ManageResource;
Resource.Read = ReadResource;
Resource.Select = SelectResource;
Resource.MultiSelect = MultiResourceSelect;
