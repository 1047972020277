"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Action_1 = require("./Action");
const Button_1 = require("./Button");
const Error_1 = require("./Error");
const Ghost_1 = require("./Ghost");
const Icon_1 = require("./Icon");
const Sentiment_1 = require("./Sentiment");
const Button = (props) => {
    return (0, jsx_runtime_1.jsx)(Button_1.Button, Object.assign({}, props));
};
exports.Button = Button;
exports.Button.Action = Action_1.ActionButton;
exports.Button.Error = Error_1.ErrorButton;
exports.Button.Ghost = Ghost_1.GhostButton;
exports.Button.Icon = Icon_1.IconButton;
exports.Button.Sentiment = Sentiment_1.SentimentButton;
