"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BoxingRing = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const BoxingRing = () => {
    return ((0, jsx_runtime_1.jsx)("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 32, height: 32 }, { children: (0, jsx_runtime_1.jsx)("path", { style: {
                lineHeight: "normal",
                textIndent: 0,
                textAlign: "start",
                textDecorationLine: "none",
                textDecorationStyle: "solid",
                textDecorationColor: "#000",
                textTransform: "none",
                whiteSpace: "normal",
                isolation: "auto",
                mixBlendMode: "normal",
            }, d: "M15.992 1023.351a.497.497 0 0 0-.492.508v.684L3 1030.097v-.238a.5.5 0 1 0-1 0v13.012a.5.5 0 0 0 .297.457l13.47 5.986.03.014c.005 0 .01.01.016.01.01 0 .02.01.03.012.017.01.033.01.05.014.016 0 .032.01.048.01h.016a.515.515 0 0 0 .05 0h.034c.033 0 .066-.01.098-.018.016-.01.031-.01.047-.018h.017l13.5-6a.5.5 0 0 0 .297-.472v-13.004a.5.5 0 1 0-1-.002v.238l-12.5-5.554v-.684a.5.5 0 0 0-.508-.508zm-.492 2.286v1.906l-9.574 4.254-2.147-.954zm1 0 11.72 5.207-2.146.953-9.574-4.254zm-1 3v1.906l-6.2 2.754-2.144-.954zm1 0 8.344 3.707-2.145.953-6.199-2.754zm-.5 2.777 5.47 2.43-4.97 2.208v-.187a.5.5 0 1 0-1 0v.188l-4.97-2.21zm-13 .18 1.695.75-1.695.753zm26 0v1.503l-1.695-.754zm-23.076 1.296 2.144.953-2.142.954-2.147-.954zm20.152 0 2.145.953-2.147.954-2.142-.954zm-16.777 1.5 6.201 2.758v1.905l-8.346-3.71zm13.402 0 2.145.953-8.346 3.71v-1.905zM3 1034.593l1.693.752-1.693.752zm26 0v1.504l-1.693-.752zm-23.076 1.297 9.576 4.258v1.947l-11.77-5.23zm20.152 0 2.194.975-11.77 5.23v-1.946zM3 1037.634l12.5 5.555v4.906L3 1042.541v-3.176zm26 0v4.907l-12.5 5.554v-4.906z", color: "currentcolor", fontFamily: "sans-serif", fontWeight: 400, overflow: "visible", transform: "translate(0 -1020.362)" }) })));
};
exports.BoxingRing = BoxingRing;
