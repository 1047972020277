import type { AthleteNamespace } from './Athlete';
import type { CardNamespace } from './Card';
import type { HomeNamespace } from './Home';
import type { NetworkNamespace } from './Network';

import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';

import { AdminLayout } from './Layout';
import { Athlete } from './Athlete';
import { Card } from './Card';
import { Home } from './Home';
import { Network } from './Network';

export interface AdminNamespace extends React.VoidFunctionComponent {
  Athlete: AthleteNamespace;
  Card: CardNamespace;
  Home: HomeNamespace;
  Network: NetworkNamespace;
}

export const Admin: AdminNamespace = () => {
  return (
    <Auth0Provider
      clientId="EbWK7r2szFdC8RlZSOG3FSP8zKEZzVCJ"
      domain="dev-fwi5bzut.us.auth0.com"
      redirectUri={window.location.origin}
    >
      <AdminLayout>
        <Outlet />
      </AdminLayout>
    </Auth0Provider>
  );
}

Admin.Athlete = Athlete;
Admin.Card = Card;
Admin.Network = Network;
Admin.Home = Home;
