import type { Athlete as IAthlete } from 'data';
import type { SelectAthleteProps } from './Select';
import type { FormComponent, GridComponent } from 'component-library';

import React from 'react';

import { AthleteForm } from './Form';
import { AthleteListTable } from './ListTable';
import { SelectAthlete } from './Select';

export interface AthleteNamespace {
  Form: FormComponent<Partial<IAthlete>>;
  ListTable: GridComponent<IAthlete>;
  Select: React.FC<SelectAthleteProps>;
}

export const Athlete: AthleteNamespace = {
  Form: AthleteForm,
  ListTable: AthleteListTable,
  Select: SelectAthlete,
}
