"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Range = exports.isInside = void 0;
/**
 * Bounding category.
 */
var Bound;
(function (Bound) {
    Bound["Closed"] = "closed";
    Bound["LeftOpen"] = "left-open";
    Bound["Open"] = "open";
    Bound["RightOpen"] = "right-open";
})(Bound || (Bound = {}));
/**
 * Side of a range.
 */
var Side;
(function (Side) {
    Side["Left"] = "left";
    Side["Right"] = "right";
})(Side || (Side = {}));
/**
 * Determine if a side is open.
 */
function isOpen(bound, side) {
    switch (bound) {
        case Bound.Closed:
            return false;
        case Bound.LeftOpen:
            return side === Side.Left;
        case Bound.Open:
            return true;
        case Bound.RightOpen:
            return side === Side.Right;
        default:
            return true;
    }
}
/**
 * Determine if a value is within a range.
 */
function isInside(range, value) {
    return ((range.start.open
        ? value > range.start.value
        : value >= range.start.value) &&
        (range.end.open
            ? value < range.end.value
            : value <= range.end.value));
}
exports.isInside = isInside;
/**
 * Describes a range.
 */
class Range {
    /**
     * Ranges default to be right-bounded.
     */
    constructor(start, end, bound = Bound.RightOpen) {
        this.start = { value: start, open: isOpen(bound, Side.Left) };
        this.end = { value: end, open: isOpen(bound, Side.Right) };
    }
    /**
     * Convert to a plain object.
     */
    toObject() {
        return {
            end: this.end.value,
            start: this.start.value,
        };
    }
    /**
     * Convert to a human readable range string.
     */
    toString() {
        const open = this.start.open ? '(' : '[';
        const close = this.end.open ? ')' : ']';
        return `${open}${this.start.value}, ${this.end.value}${close}`;
    }
    /**
     * Determine if a value is within range.
     */
    isInside(value) {
        return isInside(this, value);
    }
}
exports.Range = Range;
Range.Bound = Bound;
