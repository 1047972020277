import React from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';

import Style from 'style';
import { Icon, Link, Spinner } from 'component-library';
import { UniversalLogin } from 'component/Account';

export interface AdminLayoutProps {
  children: React.ReactNode;
}

export const AdminLayoutContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100vh;
  width: 100%;
`;

export const Sidebar = styled.div`
  display: grid;
  align-items: flex-start;
  justify-content: center;
  row-gap: ${Style.Layout.Spacing.Small};
  background: ${props => props.theme.Color.Layout.Lightest};
  padding: ${Style.Layout.Spacing.Small};
`;

export const Navigation = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  row-gap: ${Style.Layout.Spacing.Increased};
  background: ${props => props.theme.Color.Layout.Lightest};
  padding: ${Style.Layout.Spacing.Small};
  color: ${props => props.theme.Color.Text.Secondary};
`;

export const Outlet = styled.div`
  overflow-y: scroll;
  padding: ${Style.Layout.Spacing.Base};
  background: ${Style.Color.Gray.S100};
`;

export const AdminLayout: React.FC<AdminLayoutProps> = ({
  children,
}) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Spinner />
  }

  if (!isAuthenticated) {
    return <UniversalLogin />
  }

  return (
    <AdminLayoutContainer>
      <Sidebar>
        <Navigation>
          <Link.Area path="">
            <Icon>
              <Icon.Home />
            </Icon>
          </Link.Area>
          <Link.Area path="card">
            <Icon>
              <Icon.Cards />
            </Icon>
          </Link.Area>
          <Link.Area path="network">
            <Icon>
              <Icon.Television />
            </Icon>
          </Link.Area>
          <Link.Area path="athlete">
            <Icon>
              <Icon.Person />
            </Icon>
          </Link.Area>
          <Link.Area path="athlete">
            <Icon>
              <Icon.SignOut />
            </Icon>
          </Link.Area>
        </Navigation>
      </Sidebar>
      <Outlet>
        {children}
      </Outlet>
    </AdminLayoutContainer>
  );
}
