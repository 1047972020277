import React from 'react'
import styled from 'styled-components'

import { Paragraph, NoData, Spinner } from 'component-library';
import { useResource } from 'hook';

export interface ReadResourceChildrenProps<T = any> {
  resource: T;
}

export type RenderChildren<T = any> = (props: ReadResourceChildrenProps<T>) => JSX.Element;

export interface ReadResourceProps<T = any> {
  children: RenderChildren<T>;
  empty?: (React.VoidFunctionComponent | string);
  loading?: React.VoidFunctionComponent;
  read: () => Promise<T>;
}

export const DefaultLoadingContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
`;

/**
 * Default loading component.
 */
const DefaultLoading: React.VoidFunctionComponent = () => {
  return (
    <DefaultLoadingContainer>
      <Spinner />
    </DefaultLoadingContainer>
  );
}

/**
 * Default empty (no data) component.
 */
const DefaultEmpty: React.VoidFunctionComponent = () => {
  return <Paragraph>Empty.</Paragraph>;
}

/**
 * Component used to handle fetching and displaying a resource.
 */
export function ReadResource<T = any>({
  children,
  empty = DefaultEmpty,
  loading = DefaultLoading,
  read,
}: ReadResourceProps<T>) {
  const {
    hasResource,
    isEmpty,
    isLoading,
    resource,
  } = useResource<T>(read);

  if (isLoading) {
    return loading({});
  }

  if (isEmpty) {
    if (typeof empty === 'string') {
      return (
        <NoData>
          {empty}
        </NoData>
      );
    }

    if (typeof empty === 'function') {
      return empty({});
    }
  }

  if (hasResource) {
    return children({ resource });
  }

  return null;
};
