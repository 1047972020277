import type { Network as INetwork } from 'data';

import * as R from 'ramda'
import styled from 'styled-components'

import Style from 'style'
import { Network, NetworkContainer } from './Network'

export interface NetworkListProps {
  networks: INetwork[];
  onClick: (network: INetwork) => void;
}

export const NetworkListContainer = styled.div`
  display: flex;
  column-gap: ${Style.Layout.Spacing.Small};
  height: 100%;
  align-items: center;

  ${NetworkContainer} {
    &:last-child {
      border-bottom-right-radius: ${Style.Design.Rounding.Secondary};
    }
  }
`

export const NetworkList: React.FC<NetworkListProps> = ({
  networks,
  onClick,
}) => {
  return (
    <NetworkListContainer>
      {R.map((network: INetwork) => {
        return (
          <Network
            key={network.id}
            network={network}
            onClick={() => onClick(network)}
          />
        );
      }, networks)}
    </NetworkListContainer>
  );
}
