"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.matchToMatchForm = exports.createMatchForm = exports.MatchFormSchema = exports.matchCornersToMatchCornersForm = exports.createMatchCornersForm = exports.MatchCornersFormSchema = exports.matchCornerToMatchCornerForm = exports.createMatchCornerForm = exports.MatchCornerFormSchema = void 0;
const yup = require("yup");
const interface_1 = require("../../interface");
exports.MatchCornerFormSchema = yup.object({
    athlete_id: yup.string(),
});
function createMatchCornerForm() {
    return {
        athlete_id: undefined,
    };
}
exports.createMatchCornerForm = createMatchCornerForm;
function matchCornerToMatchCornerForm(matchCorner) {
    return {
        athlete_id: matchCorner.athlete_id,
    };
}
exports.matchCornerToMatchCornerForm = matchCornerToMatchCornerForm;
exports.MatchCornersFormSchema = yup.object({
    [interface_1.Corner.Blue]: exports.MatchCornerFormSchema,
    [interface_1.Corner.Red]: exports.MatchCornerFormSchema,
});
function createMatchCornersForm() {
    return {
        [interface_1.Corner.Blue]: createMatchCornerForm(),
        [interface_1.Corner.Red]: createMatchCornerForm(),
    };
}
exports.createMatchCornersForm = createMatchCornersForm;
function matchCornersToMatchCornersForm(matchCorners) {
    return {
        [interface_1.Corner.Blue]: matchCornerToMatchCornerForm(matchCorners[interface_1.Corner.Blue]),
        [interface_1.Corner.Red]: matchCornerToMatchCornerForm(matchCorners[interface_1.Corner.Red]),
    };
}
exports.matchCornersToMatchCornersForm = matchCornersToMatchCornersForm;
exports.MatchFormSchema = yup.object({
    category: yup.string().oneOf(Object.values(interface_1.MatchCategory)),
    corners: exports.MatchCornersFormSchema,
    rounds: yup.number().required().positive().integer(),
    start: yup.string(),
    weight_class: yup.string().oneOf(Object.values(interface_1.WeightClass)),
});
function createMatchForm(overrides = {}) {
    return Object.assign({ category: interface_1.MatchCategory.Middle, corners: createMatchCornersForm(), rounds: undefined, start: undefined, weight_class: undefined }, overrides);
}
exports.createMatchForm = createMatchForm;
function matchToMatchForm(match) {
    return {
        category: match.category,
        corners: matchCornersToMatchCornersForm(match.corners),
        rounds: match.rounds,
        start: match.start,
        weight_class: match.weight_class,
    };
}
exports.matchToMatchForm = matchToMatchForm;
;
