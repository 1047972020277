"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Link = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Link_1 = require("./Link");
const Area_1 = require("./Area");
const Link = (props) => {
    return (0, jsx_runtime_1.jsx)(Link_1.Link, Object.assign({}, props));
};
exports.Link = Link;
exports.Link.Area = Area_1.LinkArea;
