import type { FormComponent } from 'component-library';
import type { NavigateFunction } from 'react-router'

import React from 'react';
import { useNavigate } from 'react-router-dom'

import { Card, Button } from 'component-library';
import { FormContainer, Actions } from './style';

export interface CreateResourceProps<T> {
  Form: FormComponent<T>;
  create: (state: T) => Promise<any>;
  initial: T;
}

/**
 * Component used to create a new resource.
 */
export function CreateResource<T>({
  Form,
  create,
  initial,
}: CreateResourceProps<T>) {
  const navigate: NavigateFunction = useNavigate()
  const [ value, setValue ] = React.useState<T>(initial);

  const goToParent = React.useCallback(() => {
    navigate('../');
  }, [navigate]);

  const onCancel = React.useCallback(() => {
    goToParent();
  }, [goToParent]);

  const onCreate = React.useCallback(() => {
    create(value)
      .then(() => {
        goToParent();
      });
  }, [create, value, goToParent]);

  return (
    <Card>
      <Card.Content>
        <FormContainer>
          <Form
            value={value}
            onChange={setValue}
          />
          <Actions>
            <Button.Ghost
              onClick={onCancel}
            >
              Cancel
            </Button.Ghost>
            <Button.Action
              onClick={onCreate}
            >
              Create
            </Button.Action>
          </Actions>
        </FormContainer>
      </Card.Content>
    </Card>
  );
};
