"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Column = void 0;
const CreatedAt_1 = require("./CreatedAt");
const UpdatedAt_1 = require("./UpdatedAt");
exports.Column = {
    CreatedAt: CreatedAt_1.CreatedAtColumn,
    Timestamps: [CreatedAt_1.CreatedAtColumn, UpdatedAt_1.UpdatedAtColumn],
    UpdatedAt: UpdatedAt_1.UpdatedAtColumn,
};
