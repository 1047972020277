"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Gradient = exports.Red = exports.Blue = exports.Gray = void 0;
exports.Gray = {
    S900: '#101840',
    S800: '#474D66',
    S700: '#696F8C',
    S600: '#8F95B2',
    S500: '#C1C4D6',
    S400: '#D8DAE5',
    S300: '#E6E8F0',
    S200: '#EDEFF5',
    S100: '#F4F5F9',
    S90: '#F4F6FA',
    S75: '#F9FAFC',
    S50: '#FAFBFF',
};
exports.Blue = {
    S50: '#DEEBFF',
    S75: '#B3D4FF',
    S100: '#4C9AFF',
    S200: '#2684FF',
    S300: '#0065FF',
    S400: '#0052CC',
    S500: '#0747A6',
};
exports.Red = {
    S50: '#FFEBE6',
    S75: '#FFBDAD',
    S100: '#FF8F73',
    S200: '#FF7452',
    S300: '#FF5630',
    S400: '#DE350B',
    S500: '#BF2600',
};
exports.Gradient = {
    Blue: 'linear-gradient(135deg, #ABDCFF 10%, #0396FF 100%)',
    Green: 'linear-gradient(135deg, #81FBB8 10%, #28C76F 100%)',
};
