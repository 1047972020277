import type { CardPopulated } from 'data'

import React from 'react';

import API from 'api';
import { Card as CardComponent } from 'component/Card';
import { Resource } from 'component/resource';
import { BoundedContent } from 'component/Layout/BoundedContent';

export const ManageCard: React.VoidFunctionComponent = () => {
  return (
    <BoundedContent>
      <Resource.Manage<CardPopulated>
        read={() => API.Card.find()}
        name="Cards"
        empty="There are no cards."
        ListTable={CardComponent.ListTable}
      />
    </BoundedContent>
  );
}
