"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Definition = exports.Opacity = exports.Rounding = exports.Border = void 0;
const styled_components_1 = require("styled-components");
const Layout = require("./layout");
const Sizing = require("./sizing");
exports.Border = {
    Style: 'solid',
    Width: '1px',
};
exports.Rounding = {
    Primary: Sizing.Size.Base,
    Secondary: Sizing.Size.Small,
    Tertiary: Sizing.Size.Micro,
};
const Bounding = (0, styled_components_1.css) `
  border-color: ${(props) => props.theme.Color.Layout.Line};
  border-radius: ${exports.Rounding.Secondary};
  border-style: ${exports.Border.Style};
  border-width: ${exports.Border.Width};
`;
const Container = (0, styled_components_1.css) `
  ${Bounding};
  padding: ${Layout.Spacing.Base};
`;
exports.Opacity = {
    Opaque: '1.0',
    Translucent: '0.5',
    Transparent: '0',
};
exports.Definition = {
    Bounding,
    Container,
};
