import React from 'react';
import { Outlet } from 'react-router-dom';

import { EditAthlete } from './Edit';
import { ManageAthlete } from './Manage';
import { NewAthlete } from './New';

export interface AthleteNamespace extends React.VoidFunctionComponent {
  Edit: React.VoidFunctionComponent;
  Manage: React.VoidFunctionComponent;
  New: React.VoidFunctionComponent;
}

export const Athlete: AthleteNamespace = () => {
  return (
    <Outlet />
  );
}

Athlete.Edit = EditAthlete;
Athlete.New = NewAthlete;
Athlete.Manage = ManageAthlete;
