"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Light = void 0;
const Color = require("../color");
const Layout = {
    Background: Color.Gray.S100,
    Darkest: '#000000',
    Foreground: '#FFFFFF',
    Lightest: '#FFFFFF',
    Line: Color.Gray.S400,
};
const Text = {
    Primary: Color.Gray.S900,
    Secondary: Color.Gray.S600,
    Tertiary: Color.Gray.S400,
};
const Accent = {
    Primary: '#8862C5',
    Secondary: '#573C82',
};
const Sentiment = {
    Action: {
        Primary: '#3366FF',
        Secondary: '#85A3FF',
        Text: '#FFFFFF',
    },
    Success: {
        Primary: '#52BD95',
        Secondary: '#97D7BF',
        Text: '#FFFFFF',
    },
    Error: {
        Primary: '#D14343',
        Secondary: '#EE9191',
        Text: '#FFFFFF',
    },
    Info: {
        Primary: '#3366FF',
        Secondary: '#85A3FF',
        Text: '#FFFFFF',
    },
    Neutral: {
        Primary: Color.Gray.S100,
        Secondary: Color.Gray.S200,
        Text: Text.Secondary,
    },
};
exports.Light = {
    Text: {
        Family: {
            Primary: 'Rubik',
            Secondary: 'Playfair Display',
            Fallback: 'sans-serif',
        },
    },
    Color: {
        Accent,
        Layout,
        Sentiment,
        Text,
    },
};
