import type { Athlete as IAthlete } from 'data'

import React from 'react';

import API from 'api';
import { Athlete as AthleteComponent } from 'component/Athlete';
import { Resource } from 'component/resource';
import { BoundedContent } from 'component/Layout/BoundedContent';

export const ManageAthlete: React.VoidFunctionComponent = () => {
  return (
    <BoundedContent>
      <Resource.Manage<IAthlete>
        read={() => API.Athlete.find()}
        name="Athletes"
        empty="There are no athletes."
        ListTable={AthleteComponent.ListTable}
      />
    </BoundedContent>
  );
}
