import { Zone } from 'luxon';

export enum ActionType {
  SetTimezone = 'set_timezone',
};

export interface SetTimezoneAction {
  payload: Zone;
  type: ActionType.SetTimezone;
}

export type Action =
  SetTimezoneAction;
