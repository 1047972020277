"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Icon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const BoxingGloves_1 = require("./BoxingGloves");
const BoxingRing_1 = require("./BoxingRing");
const Calendar_1 = require("./Calendar");
const Cards_1 = require("./Cards");
const CaretCircleDown_1 = require("./CaretCircleDown");
const CaretCircleRight_1 = require("./CaretCircleRight");
const CaretDoubleDown_1 = require("./CaretDoubleDown");
const CaretDoubleUp_1 = require("./CaretDoubleUp");
const CaretDown_1 = require("./CaretDown");
const CaretRight_1 = require("./CaretRight");
const CaretUp_1 = require("./CaretUp");
const Fold_1 = require("./Fold");
const Home_1 = require("./Home");
const Container_1 = require("./Container");
const ListDown_1 = require("./ListDown");
const ListUp_1 = require("./ListUp");
const LocationPin_1 = require("./LocationPin");
const Moon_1 = require("./Moon");
const Person_1 = require("./Person");
const SignOut_1 = require("./SignOut");
const Sun_1 = require("./Sun");
const Television_1 = require("./Television");
const Time_1 = require("./Time");
const Trash_1 = require("./Trash");
const Unfold_1 = require("./Unfold");
const UserPlus_1 = require("./UserPlus");
const X_1 = require("./X");
const Icon = (props) => {
    return (0, jsx_runtime_1.jsx)(Container_1.IconContainer, Object.assign({}, props));
};
exports.Icon = Icon;
exports.Icon.BoxingGloves = BoxingGloves_1.BoxingGloves;
exports.Icon.BoxingRing = BoxingRing_1.BoxingRing;
exports.Icon.Calendar = Calendar_1.Calendar;
exports.Icon.Cards = Cards_1.Cards;
exports.Icon.CaretCircleDown = CaretCircleDown_1.CaretCircleDown;
exports.Icon.CaretCircleRight = CaretCircleRight_1.CaretCircleRight;
exports.Icon.CaretDoubleDown = CaretDoubleDown_1.CaretDoubleDown;
exports.Icon.CaretDoubleUp = CaretDoubleUp_1.CaretDoubleUp;
exports.Icon.CaretDown = CaretDown_1.CaretDown;
exports.Icon.CaretRight = CaretRight_1.CaretRight;
exports.Icon.CaretUp = CaretUp_1.CaretUp;
exports.Icon.Fold = Fold_1.Fold;
exports.Icon.Home = Home_1.Home;
exports.Icon.ListDown = ListDown_1.ListDown;
exports.Icon.ListUp = ListUp_1.ListUp;
exports.Icon.LocationPin = LocationPin_1.LocationPin;
exports.Icon.Moon = Moon_1.Moon;
exports.Icon.Person = Person_1.Person;
exports.Icon.SignOut = SignOut_1.SignOut;
exports.Icon.Sun = Sun_1.Sun;
exports.Icon.Television = Television_1.Television;
exports.Icon.Time = Time_1.Time;
exports.Icon.Trash = Trash_1.Trash;
exports.Icon.Unfold = Unfold_1.Unfold;
exports.Icon.UserPlus = UserPlus_1.UserPlus;
exports.Icon.X = X_1.X;
