import type { AthleteForm as IAthleteForm } from 'data';
import type { ModalComponentProps } from 'context';

import React from 'react';
import styled from 'styled-components';

import API from 'api';
import Style from 'style';
import { AthleteForm } from './Form';
import { Button } from 'component-library';
import { createAthleteForm } from 'data';

export const NewAthleteModalContainer = styled.div`
  display: grid;
  row-gap: ${Style.Layout.Spacing.Large};
`

export const Actions = styled.div`
  align-items: center;
  column-gap: ${Style.Layout.Spacing.Small};
  display: flex;
  justify-content: flex-end;
`

export const NewAthleteModal: React.FC<ModalComponentProps> = ({
  close,
}) => {
  const [ value, setValue ] = React.useState<IAthleteForm>(createAthleteForm());

  const create = React.useCallback((state: IAthleteForm) => {
    return API.Athlete.create(state);
  }, []);

  const onCreate = React.useCallback(() => {
    create(value)
      .then(() => {
        close();
      });
  }, [create, value, close]);

  return (
    <NewAthleteModalContainer>
      <AthleteForm
        value={value}
        isDisabled={false}
        onChange={setValue}
      />
      <Actions>
        <Button.Ghost
          onClick={() => close()}
        >
          Cancel
        </Button.Ghost>
        <Button.Action
          onClick={onCreate}
        >
          Create
        </Button.Action>
      </Actions>
    </NewAthleteModalContainer>
  );
}
