import type { GridComponent } from 'component-library';
import type { NavigateFunction } from 'react-router'
import type { ReadResourceProps } from './Read';

import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'

import Style from 'style';
import { Button, Header } from 'component-library';
import { ReadResource } from './Read';

export interface ManageResourceProps<T extends object> {
  ListTable: GridComponent<T>;
  empty?: ReadResourceProps<T>['empty'];
  loading?: ReadResourceProps<T>['loading'];
  name: string;
  read: ReadResourceProps<T[]>['read'];
}

export const ManageResourceContainer = styled.div`
  display: grid;
  row-gap: ${Style.Layout.Spacing.Increased};
`;

export const ContentContainer = styled.div`
  display: grid;
  row-gap: ${Style.Layout.Spacing.Small};
`;

export const ManageHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

/**
 * Component used to manage (administer) a type of resource.
 */
export function ManageResource<T extends object>({
  ListTable,
  empty,
  name,
  read,
}: ManageResourceProps<T>) {
  const navigate: NavigateFunction = useNavigate()

  return (
    <ManageResourceContainer>
      <ManageHeader>
        <Header>{name}</Header>
        <Button.Action
          onClick={() => navigate('new')}
        >
          Add
        </Button.Action>
      </ManageHeader>
      <ReadResource<T[]>
        read={read}
        empty={empty}
      >
        {({ resource }) => (
          <ContentContainer>
            <ListTable
              data={resource}
            />
          </ContentContainer>
        )}
      </ReadResource>
    </ManageResourceContainer>
  );
};
