"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cardHasEnded = exports.sortCards = void 0;
const R = require("ramda");
const luxon_1 = require("luxon");
const library_1 = require("library");
/**
 * Sort cards.
 */
function sortCards(cards) {
    return R.sortBy(R.prop('start'), cards);
}
exports.sortCards = sortCards;
/**
 * Determine if a card has ended.
 */
function cardHasEnded(card, zone, now = luxon_1.DateTime.now()) {
    const nextDay = R.pipe(library_1.toDateTime, (next) => {
        return next.setZone(zone.name);
    }, library_1.truncateTime, (next) => {
        return next.plus({ days: 1 });
    })(card.start);
    const nowInTimezone = now.setZone(zone.name);
    return nowInTimezone >= nextDay;
}
exports.cardHasEnded = cardHasEnded;
