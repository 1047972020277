import type { Theme } from 'style';

import React from 'react'

import { WeightClass } from 'data';
import { weightClassToLabelShort } from 'shared';
import { useTheme } from 'styled-components';

import { Pill } from 'component-library';

export interface WeightClassPillProps {
  size?: string;
  weightClass: WeightClass;
}

export type WeightClassPillComponent = React.FC<WeightClassPillProps>;

export const WeightClassPill: WeightClassPillComponent = ({
  size,
  weightClass,
}) => {
  const theme: Theme = useTheme() as Theme;

  const label: string = React.useMemo(() => {
    return weightClassToLabelShort(weightClass);
  }, [weightClass]);

  return (
    <Pill
      background={theme.Color.Layout.Background}
      foreground={theme.Color.Text.Secondary}
      size={size}
    >
      {label}
    </Pill>
  );
};
