"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toDateString = exports.truncateTime = exports.toDateTime = exports.displayTime = exports.displayDateTime = exports.toWeekdayRelativeQualifier = exports.toWeekdayShort = exports.isTomorrow = exports.isToday = exports.ONE_WEEK = exports.CONDENSED_DATE = exports.DEFAULT_TIME_FORMAT = exports.ADMIN_DATE_TIME_FORMAT = exports.DEFAULT_DATE_TIME_FORMAT = void 0;
const R = require("ramda");
const luxon_1 = require("luxon");
exports.DEFAULT_DATE_TIME_FORMAT = 'yyyy LLL dd';
exports.ADMIN_DATE_TIME_FORMAT = 'M/d/y h:mm a';
exports.DEFAULT_TIME_FORMAT = 'h:mm a';
exports.CONDENSED_DATE = 'LLL d';
exports.ONE_WEEK = luxon_1.Duration.fromObject({ weeks: 1 });
/**
 * Create an interval based off now.
 */
function intervalFromNow({ duration, now, offset = luxon_1.Duration.fromObject({ hours: 0 }), }) {
    const start = truncateTime(now).plus(offset);
    const end = start.plus(duration);
    return luxon_1.Interval.fromDateTimes(start, end);
}
/**
 * Determine if a datetime falls inside today.
 */
function isToday(datetime, now = luxon_1.DateTime.local()) {
    return datetime.hasSame(now, 'day');
}
exports.isToday = isToday;
/**
 * Determine if a datetime falls inside tomorrow.
 */
function isTomorrow(datetime, now = luxon_1.DateTime.local()) {
    return datetime.hasSame(now.plus({ days: 1 }), 'day');
}
exports.isTomorrow = isTomorrow;
/**
 * Resolves a datetime's abbreviated weekday.
 */
function toWeekdayShort(datetime) {
    return datetime.weekdayShort;
}
exports.toWeekdayShort = toWeekdayShort;
/**
 * Given a datetime get its weekday relative qualifier.
 */
function toWeekdayRelativeQualifier(datetime, now = luxon_1.DateTime.local()) {
    const thisWeek = intervalFromNow({ now, duration: exports.ONE_WEEK });
    if (isToday(datetime, now)) {
        return '';
    }
    if (thisWeek.contains(datetime)) {
        return `This`;
    }
    const nextWeek = intervalFromNow({
        duration: exports.ONE_WEEK,
        now,
        offset: exports.ONE_WEEK,
    });
    if (nextWeek.contains(datetime)) {
        return `Next`;
    }
    return '';
}
exports.toWeekdayRelativeQualifier = toWeekdayRelativeQualifier;
/**
 * Display a date time.
 */
function displayDateTime(datetime, { format = exports.DEFAULT_DATE_TIME_FORMAT } = {}) {
    return datetime.toFormat(format);
}
exports.displayDateTime = displayDateTime;
/**
 * Display a time.
 */
function displayTime(datetime) {
    return displayDateTime(datetime, { format: exports.DEFAULT_TIME_FORMAT });
}
exports.displayTime = displayTime;
/**
 * Convert a string into a DateTime.
 */
function toDateTime(value) {
    return luxon_1.DateTime.fromISO(value);
}
exports.toDateTime = toDateTime;
/**
 * Truncate the time portion of a DateTime.
 */
function truncateTime(datetime) {
    return datetime.startOf('day');
}
exports.truncateTime = truncateTime;
/**
 * Return the date only as a string.
 */
function toDateString(datetime) {
    return R.pipe(truncateTime, (next) => next.toISODate())(datetime);
}
exports.toDateString = toDateString;
