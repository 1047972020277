import React from 'react';

import { Paragraph } from 'component-library';

export interface HomeNamespace extends React.VoidFunctionComponent {}

export const Home: HomeNamespace = () => {
  return (
    <Paragraph>Welcome</Paragraph>
  );
};
