import type { AthleteForm } from 'data';

import React from 'react';

import { athleteToAthleteForm } from 'data';

import API from 'api';
import { Athlete as AthleteComponent } from 'component/Athlete';
import { Resource } from 'component/resource';
import { useParams } from 'hook';
import { BoundedContent } from 'component/Layout/BoundedContent';

export const EditAthlete: React.VoidFunctionComponent = () => {
  const { id } = useParams<{ id: string }>();

  const read = React.useCallback(async () => {
    return API.Athlete.findById(id).then(athleteToAthleteForm);
  }, [id]);

  const update = React.useCallback(async (state: AthleteForm) => {
    return API.Athlete.update(id, state);
  }, [id]);

  const remove = React.useCallback(async () => {
    return API.Athlete.remove(id);
  }, [id]);

  return (
    <BoundedContent>
      <Resource.Layout
        title="Edit Athlete"
      >
        <Resource.Edit<AthleteForm>
          Form={AthleteComponent.Form}
          read={read}
          remove={remove}
          update={update}
        />
      </Resource.Layout>
    </BoundedContent>
  );
}
