import type { FormComponent, GridComponent } from 'component-library';
import type { Network as INetwork } from 'data';
import type { NetworkListProps } from './List';
import type { NetworkProps } from './Network';
import type { MultiSelectNetworkProps } from './MultiSelect';

import React from 'react';

import { Network as BaseNetwork } from './Network';
import { NetworkForm } from './Form';
import { NetworkList } from './List';
import { NetworkListTable } from './ListTable';
import { MultiSelectNetwork } from './MultiSelect';

export interface NetworkNamespace {
  Form: FormComponent<Partial<INetwork>>;
  List: React.FC<NetworkListProps>;
  ListTable: GridComponent<INetwork>;
  MultiSelect: React.FC<MultiSelectNetworkProps>;
}

export const Network: NetworkNamespace = (props: NetworkProps) => {
  return <BaseNetwork {...props} />
}

Network.List = NetworkList;
Network.ListTable = NetworkListTable;
Network.Form = NetworkForm;
Network.MultiSelect = MultiSelectNetwork;
