"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Spinner = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Scale_1 = require("./Scale");
const Spinner = (props) => {
    return (0, jsx_runtime_1.jsx)(Scale_1.ScaleSpinner, Object.assign({}, props));
};
exports.Spinner = Spinner;
exports.Spinner.Scale = Scale_1.ScaleSpinner;
