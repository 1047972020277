import React from 'react';
import { Outlet } from 'react-router-dom';

import { EditCard } from './Edit';
import { ManageCard } from './Manage';
import { NewCard } from './New';

export interface CardNamespace extends React.VoidFunctionComponent {
  Edit: React.VoidFunctionComponent;
  Manage: React.VoidFunctionComponent;
  New: React.VoidFunctionComponent;
}

export const Card: CardNamespace = () => {
  return (
    <Outlet/>
  );
}

Card.Edit = EditCard;
Card.New = NewCard;
Card.Manage = ManageCard;
