"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_SCORE = exports.DEFAULT_CORNER_SCORE = exports.Outcome = void 0;
const match_1 = require("../match");
var Outcome;
(function (Outcome) {
    Outcome["Draw"] = "draw";
    Outcome["Lose"] = "lose";
    Outcome["Undecided"] = "undecided";
    Outcome["Win"] = "win";
})(Outcome = exports.Outcome || (exports.Outcome = {}));
exports.DEFAULT_CORNER_SCORE = {
    deducted: 0,
    points: undefined,
};
exports.DEFAULT_SCORE = {
    [match_1.Corner.Blue]: exports.DEFAULT_CORNER_SCORE,
    [match_1.Corner.Red]: exports.DEFAULT_CORNER_SCORE,
};
