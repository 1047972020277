import type { FormComponent } from 'component-library';
import type {
  MatchCornerForm as IMatchCornerForm,
  MatchCornersForm as IMatchCornersForm,
} from 'data';

import { Corner } from 'data';

import { Paragraph, FormRow, Form, FormInput } from 'component-library';
import { MatchCornerForm } from './MatchCornerForm';

export type MatchCornersFormComponent = FormComponent<IMatchCornersForm>;

export const MatchCornersForm: MatchCornersFormComponent = ({
  onChange,
  value,
}) => {
  return (
    <Form>
      <FormRow>
        <FormInput<IMatchCornerForm, IMatchCornersForm>
          Component={MatchCornerForm}
          accessor={Corner.Red}
          label="Red Corner"
          onChange={onChange}
          source={value}
        />
        <Paragraph>vs.</Paragraph>
        <FormInput<IMatchCornerForm, IMatchCornersForm>
          Component={MatchCornerForm}
          accessor={Corner.Blue}
          label="Blue Corner"
          onChange={onChange}
          source={value}
        />
      </FormRow>
    </Form>
  );
}
