import * as R from 'ramda';
import React from 'react';

// Return signature when resource is loading.
export interface UseResourceLoading {
  error: undefined;
  hasError: false;
  hasResource: false;
  isEmpty: undefined;
  isLoading: true;
  resource: undefined;
}

// Return signature when resource has loaded.
export interface UseResourceLoaded<T = any> {
  error: undefined;
  hasError: false;
  hasResource: boolean;
  isEmpty: boolean;
  isLoading: false;
  resource: T;
}

// Return signature when resource loading encountered an error.
export interface UseResourceError<E = unknown> {
  error: E;
  hasError: true;
  hasResource: false;
  isEmpty: undefined;
  isLoading: false;
  resource: undefined;
}

// Return signature union.
export type UseResource<T = any, E = unknown> =
  UseResourceLoading |
  UseResourceLoaded<T> |
  UseResourceError<E>;

/**
 * Useful to assist with loading a resource.
 */
export function useResource<T = any, E = unknown>(
  getResource: () => Promise<T>,
  deps: any[] = []
) {
  const [ resource, setResource ] = React.useState<T | undefined>();
  const [ error, setError ] = React.useState<E | undefined>(undefined);
  const [ isLoading, setIsLoading ] = React.useState<boolean>(true);

  // Load the resource when dependencies change.
  React.useEffect(() => {
    async function handleGetResource(): Promise<void> {
      setIsLoading(true);

      try {
        const result: T = await getResource();

        setResource(result);
      } catch (err: unknown) {
        setError(err as E);
      }

      setIsLoading(false);
    }

    handleGetResource();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);

  // Determine if there is an error.
  const hasError: boolean = React.useMemo(() => {
    return R.isNil(error);
  }, [error]);

  // Determine if there is a resource.
  const hasResource: boolean = React.useMemo(() => {
    return !R.isNil(resource);
  }, [resource]);

  // Determine if the resource is empty.
  const isEmpty: boolean | undefined = React.useMemo(() => {
    if (R.isNil(resource)) {
      return undefined;
    }

    return R.isEmpty(resource);
  }, [resource]);

  const result = {
    error,
    hasError,
    hasResource,
    isEmpty,
    isLoading,
    resource,
  };

  if (error !== undefined || hasError === true) {
    return result as UseResourceError<E>;
  } else if (isLoading === true) {
    return result as UseResourceLoading;
  }

  return result as UseResourceLoaded;
}
