"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Color = require("./color");
const Depth = require("./depth");
const Design = require("./design");
const Layout = require("./layout");
const Sizing = require("./sizing");
const Text = require("./text");
const Theme = require("./theme");
const Style = {
    Color,
    Depth,
    Design,
    Layout,
    Sizing,
    Text,
    Theme,
};
exports.default = Style;
