import type { CardFormComponent } from './Form';
import type { CardPopulated } from 'data';
import type { GridComponent } from 'component-library';

import { CardForm } from './Form';
import { CardListTable } from './ListTable';

export interface CardNamespace {
  Form: CardFormComponent;
  ListTable: GridComponent<CardPopulated>;
}

export const Card: CardNamespace = {
  Form: CardForm,
  ListTable: CardListTable,
}
