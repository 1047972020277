import type { NetworkForm } from 'data';

import React from 'react';

import { createNetworkForm } from 'data';

import API from 'api';
import { Network as NetworkComponent } from 'component/Network';
import { Resource } from 'component/resource';
import { BoundedContent } from 'component/Layout/BoundedContent';

export const NewNetwork: React.VoidFunctionComponent = () => {
  const [ initial ] = React.useState<NetworkForm>(createNetworkForm());

  const create = React.useCallback((state: NetworkForm) => {
    return API.Network.create(state);
  }, []);

  return (
    <BoundedContent>
      <Resource.Layout
        title="New Network"
      >
        <Resource.Create<NetworkForm>
          Form={NetworkComponent.Form}
          create={create}
          initial={initial}
        />
      </Resource.Layout>
    </BoundedContent>
  );
}
