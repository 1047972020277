import type { FormComponent } from 'component-library';
import type { NetworkForm as INetworkForm } from 'data';

import { Form, FormInput, Input } from 'component-library';

export const NetworkForm: FormComponent<INetworkForm> = ({
  isDisabled,
  onChange,
  value,
}) => {
  return (
    <Form>
      <FormInput<string, INetworkForm>
        onChange={onChange}
        accessor="name"
        label="Name"
        source={value}
        Component={Input.Text}
      />
      <FormInput<string, INetworkForm>
        onChange={onChange}
        accessor="network_url"
        label="URL"
        source={value}
        Component={Input.Text}
      />
      <FormInput<string, INetworkForm>
        onChange={onChange}
        accessor="logo_url"
        label="Logo"
        source={value}
        Component={Input.Image}
      />
    </Form>
  );
}
