import type { CardForm } from 'data';

import React from 'react';

import { createCardForm } from 'data';

import API from 'api';
import { Card as CardComponent } from 'component/Card';
import { Resource } from 'component/resource';
import { BoundedContent } from 'component/Layout/BoundedContent';

export const NewCard: React.VoidFunctionComponent = () => {
  const [ initial ] = React.useState<any>(createCardForm());

  const create = React.useCallback((state: CardForm) => {
    return API.Card.create({
      ...state,
      networks: Array.from(state.networks),
    });
  }, []);

  return (
    <BoundedContent>
      <Resource.Layout
        title="New Card"
      >
        <Resource.Create
          Form={CardComponent.Form}
          initial={initial}
          create={create}
        />
      </Resource.Layout>
    </BoundedContent>
  );
}
