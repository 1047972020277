import type { Network as INetwork } from 'data'

import React from 'react';

import API from 'api';
import { Network as NetworkComponent } from 'component/Network';
import { Resource } from 'component/resource';
import { BoundedContent } from 'component/Layout/BoundedContent';

export const ManageNetwork: React.VoidFunctionComponent = () => {
  return (
    <BoundedContent>
      <Resource.Manage<INetwork>
        read={() => API.Network.find()}
        name="Networks"
        empty="There are no networks."
        ListTable={NetworkComponent.ListTable}
      />
    </BoundedContent>
  );
}
