import type { Action } from './action';
import type { State } from './type';

import { ActionType } from './action';

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.SetTimezone:
      return {
        timezone: action.payload
      };
    default:
      return state;
  }
};
