"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.API = void 0;
const R = require("ramda");
const axios_1 = require("axios");
const constant_1 = require("./constant");
const resource_1 = require("./resource");
const auth_1 = require("./auth");
/**
 * Given an environment get the environment specific API config.
 */
function environmentToConfig(environment) {
    return R.propOr(constant_1.EnvironmentAPIConfig[constant_1.Environment.Local], environment, constant_1.EnvironmentAPIConfig);
}
/**
 * Resolve the API config.
 */
function resolveAPIConfig(options) {
    const environmentConfig = environmentToConfig(options.environment);
    return (R.mergeAll([constant_1.DEFAULT_API_CONFIG, environmentConfig, options]));
}
/**
 * Create an API client.
 */
function API(options) {
    // Resolve the config.
    const config = resolveAPIConfig(options);
    // Create an axios instance.
    const axios = axios_1.default.create({
        baseURL: config.baseURL,
    });
    // Create an authenticated instance (w/ Authorization header).
    const authenticated = (0, auth_1.addAuth)({
        axios,
        authorization: config.authorization,
    });
    // Resolve the resource config.
    const resourceConfig = { axios, authenticated };
    return R.mapObjIndexed((constructor) => {
        return constructor(resourceConfig);
    }, resource_1.Resource);
}
exports.API = API;
