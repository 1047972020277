import type { Option, FormComponentProps } from 'component-library';

import * as R from 'ramda';
import React from 'react';

import { Select } from 'component-library';

export interface SelectResourceProps<T, V = T> extends FormComponentProps<V> {
  fetch: () => Promise<T[]>;
  toOption: (resource: T) => Option<V>;
}

/**
 * Component used to select a resource.
 */
export function SelectResource<T, V = T>({
  fetch,
  onChange,
  toOption,
  value,
}: SelectResourceProps<T, V>) {
  const [ resources, setResources ] = React.useState<T[] | undefined>();

  React.useEffect(() => {
    fetch().then(setResources);
  }, [fetch]);

  const options: Option<V>[] = React.useMemo(() => {
    if (resources === undefined) {
      return [];
    }

    return R.map(toOption, resources);
  }, [resources, toOption]);

  return (
    <Select<V>
      onChange={onChange}
      options={options}
      value={value}
    />
  );
};
